const logoData = {
  dark: '/assets/img/uerba/dark-mode-no-bg.png',
  light: '/assets/img/uerba/no-bg.png',
  icon: '/assets/img/uerba/icon.png',
  square: '/assets/img/uerba/icon-sq.png',
  favicon: '/assets/img/uerba/favicon/favicon-32x32.png',
}

const HeroData = {
  title: 'o coração 💙 da transformação digital para empresas locais',
  description: 'Envolva o seu negócio na emoção da inovação com a tecnologia de ponta que a nossa plataforma tem, fique à frente da concorrência, e prepare o terreno para um sucesso incomparável.',
  buttons: [
    {
      classOption: 'btn btn-primary me-3',
      title: 'Saiba como',
      link: '/solutions'
    },
    {
      classOption: 'btn btn-outline-primary',
      title: 'Fale conosco',
      link: '/request-demo'
    },
  ]
}

const FeatureData ={
  title: 'Assegure o controle total sobre a sua empresa em uma só plataforma',
  description: 'Desde gestão de inventário, relações públicas, contabilidade, integrações com as mais principais e populares ferramentas de software online - nós oferecemos de tudo!',
  img: '/assets/img/animations/security.svg',
  icons: [
    {
      classOption: 'fa-solid fa-fingerprint fa-2x text-primary',
      title: 'Privacidade e Segurança',
      description: 'Implementamos as medidas necessárias para manter as informações pessoais seguras, incluindo criptografia, processamento de pagamentos seguro e avaliações regulares de segurança.'
    },
    {
      classOption: 'fa-solid fa-cloud fa-2x text-primary',
      title: 'Armazenamento em cloud',
      description: 'Acede à tua plataforma de onde quer que estejas, a qualquer hora, sem restrições'
    },
  ]
}

const SocialData = [
  {
    classOption: 'fa-instagram',
    link: 'https://www.instagram.com/uerba.site/'
  },
  {
    classOption: 'fa-twitter',
    link: 'https://twitter.com/uerbaSITE'
  },
]

const SolutionsData = {
  prefix: '/solutions',

  items: [
    {
      icon: 'fi fi-ss-chart-user',
      slug: 'freelancer',
      colour: 'primary',
      title: 'Particulares',
      description: 'O nosso pacote a particulares oferece uma variedade de recursos que permitem manter os teus projetos, serviços e finanças em um só lugar. Além disso, podes optar por adquirir integrações com QuickBooks e outras plataformas que te vão ajudar a acompanhar e legalizar melhor a atividade de negócio.',
      features: [
        'Até 5 Usuários',
        'Painel de controlo',
        'Gestão de Inventário',
        'Produtos e Serviços',
        'Gestão de Encomendas e Armazéns',
        'Faturação e Orçamentos',
        'Gestão de Projetos',
        'Integração do Stripe',
        'Relatórios Automizados',
      ],
      plans: {
          title: 'Básico',
          month: '5€',
          year: '49.90€',
          apps: [
            {title: 'Produtos e Serviços', slug: 'product-service'},
            {title: 'Projetos', slug: 'project'},
          ],
      },
      extras: [
        'add-users',
      ],
    },

    {
      highlight: true,

      icon: 'fi fi-ss-store-alt',
      slug: 'local-commerce',
      colour: 'danger',
      badge: {title: '🔥 Hot', colour: 'danger'},
      title: 'Comércio Local',
      description: 'O pacote ideal e mais completo para a gestão da tua loja de comércio local. Desde produtos, serviços, contabilidade e gestão de contas, podes aceder a tudo, em qualquer lugar que estejas. ',
      features: [
        'Até 15 Usuários',
        'Painel de controlo dedicado',
        'Gestão de Inventário',
        'Produtos e Serviços',
        'Gestão de Encomendas e Armazéns',
        'Gestão de Contabilidade',
        'Faturação e Orçamentos',
        'Integração do Stripe',
        'Relatórios Automizados',
      ],
      plans: {
        title: 'Básico',
        month: '48€',
        year: '479.90€',
        apps: [
          {title: 'Produtos e Serviços', slug: 'product-service'},
          {title: 'Contabilidade', slug: 'accounting'},
        ],
      }
    },

    {
      icon: 'fi fi-ss-barber-shop',
      slug: 'beauty-salon',
      badge: {title: '🎇 Novo', colour: 'success'},
      colour: 'warning',
      title: 'Salão de Beleza',
      description: 'O pacote de salão de beleza está preparado para revolucionar a gestão de salões. Desde processos de reserva simplificados e opções de pagamento flexíveis, e ferramentas eficientes de gestão de empresa, este pacote é uma prova do compromisso com a simplicidade e a eficiência. Os proprietários de salões de beleza agora podem elevar as suas operações e proporcionar uma experiência incomparável aos seus clientes, estabelecendo um novo padrão na indústria de beleza e spa.',
      features: [
        'Até 15 Usuários',
        'Painel de controlo dedicado',
        'Gestão de Apontamentos e Agendamento',
        'Personalisação de página pública',
        'Gestão de Serviços',
        'Gestão de Contabilidade',
        'Faturação e Precificação',
        'Integração do Stripe',
        'Relatórios Automizados',
      ],
      plans: {
        title: 'Básico',
        month: '73€',
        year: '729.90€',
        apps: [
          {title: 'Gestão de Salão', slug: 'beauty-salon'},
          {title: 'Produtos e Serviços', slug: 'product-service'},
          {title: 'Contabilidade', slug: 'accounting'},
        ],
      }
    },

  ]
}

const ServicesData = {
  title: 'Os melhores serviços para aumentar o valor do seu negócio',
  subtitle: 'Outros serviços',
  description: '',
  prefix: '/services',
  
  items: [
    {
      highlight: true,
      icon: 'fi fi-ss-layout-fluid',
      slug: 'website-design',
      colour: 'success',
      title: 'Design de Website',
      description: 'Projetamos e desenvolvemos websites que capturam o teu mundo, criam fidelidade à marca e criam experiências para os teus clientes, ao mesmo tempo que aumentam vitalmente a tua receita.',
      features: [
        'Design moderno e elegante que combina com a marca da sua empresa',
        'Navegação informativa que comunica aos seus clientes exatamente quais os serviços que oferece',
        'Carregamento rápido para todas as páginas do site',
        'Páginas responsivas para que o site funcione perfeitamente em todos os tipos de dispositivos'
      ],
      tabs: [
        {
          title: 'Moderno', 
          caption: 'Design moderno e elegante que combina com a marca da sua empresa', 
          description: 'Através de uma atenção meticulosa aos detalhes e de uma compreensão profunda da identidade da sua marca, criamos websites visualmente impressionantes que cativam o seu público e deixam uma impressão duradoura.',
          img: '/assets/img/animations/website-designing.svg'
        },
        {
          title: 'Informativo', 
          caption: 'Navegação informativa que comunica aos seus clientes exatamente quais os serviços que oferece', 
          description: 'Seja através de textos claros e simples, imagens atraentes ou componentes interativos, projetamos sistemas de navegação que envolvem e educam os visitantes, construindo um vínculo mais forte com sua marca.',
          img: '/assets/img/animations/website-marketing.svg'
        },
        {
          title: 'Responsivo', 
          caption: 'Páginas responsivas para que o site funcione perfeitamente em todos os tipos de dispositivos', 
          description: 'É fundamental que o seu site continue utilizável e acessível em todas as plataformas, dada a utilização generalizada de dispositivos móveis. Por isso, valorizamos muito os conceitos de design responsivo, que garantem que seu site funcionará perfeitamente em computadores, portáteis, tablets e smartphones, além de ter uma ótima aparência.',
          img: '/assets/img/animations/ux-responsive.svg'
        },
      ],
      faqs: [
        {title: 'O que envolve o serviço de design de website?', description: 'O nosso serviço de design de websote oferece soluções abrangentes sob medida para atender às tuas necessidades. Da consulta inicial ao design, desenvolvimento e implantação, trabalhamos em conjunto para criar um site que reflita a identidade da marca e atinja os teus objetivos de negócios.'},
        {title: 'Quanto tempo leva para a entrega do site?', description: 'A complexidade do projeto e tuas necessidades específicas determinarão quanto tempo levará para desenvolver o site. O nosso objetivo é fornecer designs de primeira linha em um prazo razoável, garantindo entrega rápida sem sacrificar a qualidade.'},
        {title: 'Oferecem designs de website personalizados ou usam modelos?', description: 'A nossa área de especialização é projetar sites exclusivos que atendam às tuas necessidades e marca. Embora, se preferires, podemos trabalhar com modelos, o nosso principal objetivo é fornecer designs diferenciados e inovadores que façam com que o teu site se destaque na web.'},
        {title: 'Quais plataformas ou tecnologias usam para o design de website?', description: 'A nossa equipa utiliza tecnologias de ponta, como Vue e ReactJS, para desenvolver sites únicos e dinâmicos. Embora, também utilizamos tecnologias básicas como HTML5, CSS3 e Javascript para projetos com critérios menos rigorosos para garantir o melhor desempenho e funcionalidade possíveis.'},
        {title: 'Fornecem design responsivo para dispositivos móveis?', description: 'Com certeza, cada um dos designs é totalmente responsivo e otimizado para uso em computadores, tablets e dispositivos móveis. Sabemos como é importante oferecer aos nossos clientes uma experiência consistente em todas as plataformas e garantimos que teu site terá uma ótima aparência e funcione perfeitamente em todos os tamanhos de tela.'},
        {title: 'Qual é a política de reembolsos ou garantias?', description: 'O nosso objetivo é fornecer aos nossos clientes websites e suporte excepcionais, e respeitamos o nosso trabalho com uma garantia de satisfação. Informa-nos se não estiveres totalmente satisfeito com o resultado e trabalharemos juntos para o resolver.'}
      ],
      plans: {
        title: 'Básico',
        payment: '249€',
        apps: [
          {title: '', slug: ''},
        ],
      },
    },
    {
      icon: 'fi fi-ss-pencil-paintbrush',
      slug: 'branding-identity',
      colour: 'primary',
      title: 'Identidade e Design de Marca',
      description: 'O branding está no centro de tudo o que fazemos, desde a descoberta inicial até ao lançamento final da marca, entregamos resultados excepcionais.',
      features: [
        '',
      ],
      tabs: [
        {
          title: 'Estratégias', 
          caption: 'Soluções estratégicas de branding para impulsionar a tua empresa', 
          description: 'Reconhecemos o valor de ter uma base sólida de marca. A nossa equipa qualificada é especializada no desenvolvimento de estratégias de marca personalizadas que se conectam ao teu público-alvo, diferenciam a tua empresa dos teus concorrentes e promovem o sucesso a longo prazo. Podemos ajudár-te a definir e comunicar a proposta de valor distintiva da tua marca, desde pesquisas de mercado minuciosas até posicionamento estratégico e mensagens, para garantir que todas as facetas da estratégia da tua marca estejam alinhadas com os teus objetivos corporativos.',
          img: '/assets/img/animations/brand-strategy.svg'
        },
        {
          title: 'Identidade Visual', 
          caption: 'Melhora a identidade visual da tua marca com um design impressionante', 
          description: 'Acreditamos que uma identidade visual forte é essencial para causar uma impressão duradoura. Os nossos designers talentosos são especializados na criação de elementos de marca visualmente cativantes que capturam a essência da tua marca e deixam uma impressão memorável ao público. Desde o design do logotipo e tipografia, até paletas de cores e elementos gráficos, trabalhamos em colaboração para desenvolver uma identidade visual que reflita a personalidade, os valores e as aspirações da tua marca.',
          img: '/assets/img/animations/brand-plan.svg'
        },
        {
          title: 'Consistência', 
          caption: 'Integra perfeitamente a identidade da tua marca em todas as plataformas', 
          description: 'Nós entendemos a importância de uma identidade de marca coesa em todos os pontos de contato. A nossa equipa é especializada em integrar perfeitamente a identidade da tua marca em vários canais e plataformas, garantindo uma experiência de marca unificada para o teu público. Desde plataformas digitais e redes sociais até espaços físicos e embalagens, trabalhamos diligentemente para garantir que a identidade da tua marca seja comunicada e reforçada de forma consistente em todos os pontos de interação.',
          img: '/assets/img/animations/brand-mockup.svg'
        },
      ],
      faqs: [
        {title: 'O que envolve o seu serviço de identidade e design de marca?', description: 'O nosso serviço engloba uma gama abrangente de soluções que visam definir, refinar e amplificar a essência e a identidade visual da tua marca. Desde a estratégia e posicionamento da marca ao design do logotipo, diretrizes da marca e desenvolvimento de materiais colaterais, colaboramos estreitamente para criar uma identidade de marca coesa e impactante que ressoe com teu público-alvo.'},
        {title: 'Quais metodologias usam para o desenvolvimento da marca?', description: 'O nosso método de desenvolvimento de marcas é baseado em planejamento estratégico, investigação imaginativa e trabalho cooperativo em equipe. Para obter uma melhor compreensão do teu setor, público-alvo e ambiente competitivo, primeiro fazemos uma extensa pesquisa nos mesmos. Depois disso, colaboramos em estabelecer o posicionamento diferenciado, a mensagem e a identidade visual da tua marca, garantindo que estejam alinhados com os teus objetivos para a empresa.'},
        {title: 'Como garantem a consistência em diferentes pontos de contato da marca?', description: 'Estabelecer uma identidade de marca robusta e identificável requer consistência. A nossa equipa cria diretrizes de marca completas que especificam como os aspectos da tua marca devem ser usados de forma consistente em vários pontos de contato, como materiais impressos, plataformas de internet, sinalização, embalagens e para aí além. Ao fazer isso, podemos garantir que a tua marca se parece consistente e elegante em todas as plataformas a serem usadas.'},
        {title: 'Fornecem suporte contínuo após a identidade da marca estar estabelecida?', description: 'Sim, oferecemos suporte contínuo para ajudár-te a manter e desenvolver a identidade da tua marca ao longo do tempo. Se precisares de ajuda para implementar a tua marca em novas iniciativas, refinar as mensagens ou atualizar a identidade visual à medida que a empresa cresce, a nossa equipa está aqui para apoiár-te a cada passo do processo.'},
        {title: 'Podem ajudar na reformulação de uma marca existente?', description: 'Absolutamente! Especializamo-nos em ajudar empresas a atualizar e revitalizar as suas identidades de marca por meio de iniciativas estratégicas de rebranding. Esteja a empresa a passar por uma fusão, a reposicionar-se no mercado ou simplesmente à procura de modernizar a marca, podemos orientár-te durante o processo de rebranding, desde a concepção até à execução.'},
        {title: 'Como medem o sucesso de um projeto de branding?', description: 'A eficácia de um projeto de branding é determinada pela forma como afeta as metas e objetivos da empresa. Para avaliar a eficácia das nossas soluções de branding, monitorizamos importantes métricas de desempenho, incluindo interação com o cliente, reconhecimento da marca, percepção da marca e crescimento do negócio. O nosso objetivo é desenvolver marcas que, além de visualmente atraentes, proporcionem resultados perceptíveis para cada empresa.'},
      ],
      plans: {
        title: '',
        payment: '5€',
        apps: [
          {title: 'Produtos e Serviços', slug: 'product-service'},
          {title: 'Projetos', slug: 'project'},
        ],
      },
    },
  ]
}

const AppsData = {
  prefix: '/apps',

  items: [
    {
      highlight: true,
      icon: 'fa-regular fa-calendar-check',
      slug: 'appointments',
      colour: 'primary',
      title: 'Apontamentos',
      description: 'O nosso sistema de agendamento de compromissos pronto a usar simplifica o gerenciamento de compromissos, permitindo organização, rastreamento e otimização eficientes para uso comercial ou pessoal.',
      features: [
        'Painel de controlo',
        'Botão de calendário',
        'Customização de cor',
        'Categorias e tipos de reunião',
        'Formulário de gestão e cancelamento de reunião',
        'Formulário de retorno de chamada',
        'Formulário de feedback após reunião concluída',
        'Botão de copiar link para facilitar o envio do formulário'
      ],
    },

    {
      icon: 'fa-regular fa-calendar',
      slug: 'calendar',
      colour: 'danger',
      title: 'Calendário',
      description: 'Um calendário de eventos é uma ferramenta vital para os usuários agilizarem tarefas, concentrando-se na seleção de datas. O nosso oferece uma apresentação visual em forma de calendário mensal, economizando tempo e esforço.',
      features: [
        'Monitoramento de pedidos',
        'Organização de conteúdo',
        'Filtragem de resultados de pesquisa',
        'Atualização de faturas, contas, reuniões, chamadas',
        'Feriados, eventos, tarefas, etc',
        'Integração com o Google Drive'
      ],
    },

    {
      icon: 'fa-solid fa-inbox',
      slug: 'email-box',
      colour: 'success',
      badge: {title: '⏱ Soon', colour: 'warning'},
      title: 'E-Mail Box',
      description: 'O módulo E-Mail Box é uma ferramenta poderosa projetada para agilizar o seu processo de gerenciamento de e-mail. Ele é integrado perfeitamente ao seu servidor SMTP, oferecendo controle sem precedentes sobre as diversas pastas da sua conta de e-mail.',
      features: [
        'Caixa de entrada',
        'Rascunhos',
        'Spam',
        'Enviados',
        'Lixo',
        'Arquivos',
      ],
    },

  ]
}

const ExtrasData = [
  {
    slug: 'add-users',
    icon: 'fi fi-ss-users-medical',
    badge: {title: '🔥 Hot', colour: 'primary'},

    title: '+15 Usuários',
    description: 'Permite um impulso ao crescimento da tua plataforma ao adquirires 15 contas de acesso extra.',
  },
  {
    slug: 'add-workspace',
    icon: 'fi fi-ss-users-medical',
    title: '+1 Área de Trabalho',
    description: '',
  },
]

const IntegrationsData = [
  {
    className: 'integration-1',
    badge: {title: 'Novo', colour: 'success'},
    title: 'Google Drive',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/google-drive.svg',
      screenshot: '/assets/img/integrations/screenshots/google-drive.png',
    },
  },
  {
    className: 'integration-2',
    title: 'Google Analytics',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/google-analytics.svg',
      screenshot: '/assets/img/integrations/screenshots/google-drive.png',
    },
  },
  {
    className: 'integration-3',
    title: 'Mailchimp',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/mailchimp.svg',
      screenshot: '/assets/img/integrations/screenshots/mailchimp.png',
    },
  },
  {
    className: 'integration-4',
    title: 'Shopify',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/shopify.svg',
      screenshot: '/assets/img/integrations/screenshots/shopify.png',
    },
  },
  {
    className: 'integration-5',
    title: 'Zoom App',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/zoom-app.svg',
      screenshot: '/assets/img/integrations/screenshots/zoom-app.png',
    },
  },
  {
    className: 'integration-6',
    title: 'Whatsapp',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/whatsapp.svg',
      screenshot: '/assets/img/integrations/screenshots/whatsapp.png',
    },
  },
  {
    className: 'integration-7',
    title: 'Telegram',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/telegram.svg',
      screenshot: '/assets/img/integrations/screenshots/telegram.png',
    },
  },
  {
    className: 'integration-8',
    title: 'Google Meet',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/google-meet.svg',
      screenshot: '/assets/img/integrations/screenshots/google-meet.png',
    },
  },
  {
    className: 'integration-9',
    title: 'Google Calendar',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/google-calendar.svg',
      screenshot: '/assets/img/integrations/screenshots/google-calendar.png',
    },
  },
  {
    className: 'integration-10',
    title: 'QuickBooks',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/quickbooks.svg',
      screenshot: '/assets/img/integrations/screenshots/quickbooks.png',
    },
  },
  {
    className: 'integration-11',
    title: 'Stripe',
    caption: '',
    description: '',
    img: {
      icon: '/assets/img/integrations/icons/stripe.svg',
      screenshot: '/assets/img/integrations/screenshots/stripe.png',
    },
  },
]

const NavData = {
  button: {title: 'Entra em contacto', link: '/request-demo', className: ''},
  items: [  
    {
      classOption: 'nav-item dropdown',
      title: 'Soluções',
      left: {
        title: 'Soluções',
        link: '/solutions',
        items: SolutionsData.items
      },
      right: {
        title: 'Serviços',
        link: '/services',
        items: ServicesData.items
      },
    },
    {
      classOption: 'nav-link',
      title: 'Contactos',
      link: '/contact-us',
    },
  ],
}

const TermsData = {
  title: 'Termos e Condições',
  date: 'Janeiro 1, 2024',
  footer: 'Ao usar uerba.site, você concorda com estes Termos e quaisquer modificações futuras. É sua responsabilidade revisar os Termos periodicamente para atualizações.',
  introduction: [
    'Bem-vindo à uerba.site (a "Plataforma"). Estes Termos e Condições ("Termos") regem o uso da nossa plataforma, gerida pela uerba., uma marca registrada no Reino Unido. Ao acessar e usar a nossa plataforma, você concorda em cumprir estes Termos. Se não concordar com estes Termos, por favor não se registe na nossa plataforma.',
    'uerba.site oferece mais do que serviços; proporciona uma jornada transformadora que eleva as marcas, expande o alcance, e estimula o crescimento sustentável dos negócios. Feito sob-medida especificamente para o ecossistema empresarial local, a uerba.site é a sua bússola para navegar no cenário digital com confiança. Defendendo o empoderamento local, os nossos serviços foram desenhados para impulsionar o seu negócio para novos patamares. A sua história de sucesso digital começa aqui.',
    'Ao acessar e utilizar a uerba.site, você celebra um acordo mútuo conosco, descrito nos termos e condições que seguem. Estes termos servem como roteiro, orientando a sua interação com a plataforma, garantindo uma experiência segura, justa e enriquecedora para todos os seus utilizadores.',
    'Caso tenha alguma dúvida ou precise de esclarecimentos sobre estes termos, a nossa equipe está aqui para atendê-lo. Não hesite em contactar-nos através do e-mail <a href:"mailto:geral@uerba.site">geral@uerba.site</a> para quaisquer questões relacionadas com os termos e condições aqui descritos.'
  ],
  categories: [
    {
      slug: 'eligibility',
      title: '1. Elegibilidade',
      terms: [
        '1.1 Os usuários devem ter pelo menos 18 anos para se cadastrar em uma conta no uerba.site. Ao criar uma conta, você afirma ter pelo menos 18 anos de idade e ser capaz de celebrar acordos juridicamente vinculativos.',
        '1.2 Se você estiver acessando a Plataforma em nome de uma entidade comercial, você garante que tem autoridade para vincular essa entidade a estes Termos, e “você” se referirá a essa entidade.',
        '1.3 uerba.site reserva-se o direito de solicitar prova de idade e identidade para verificar sua elegibilidade. O não fornecimento de tal documentação pode resultar na suspensão ou encerramento de sua conta.',
        '1.4 Se chegarmos ao nosso conhecimento de que uma conta foi criada por um indivíduo menor de 18 anos, desativaremos imediatamente a conta e excluiremos quaisquer informações associadas.',
        '1.5 uerba.site pode, a seu critério, recusar serviço, encerrar contas ou restringir o acesso a determinados recursos a qualquer pessoa, a qualquer momento e por qualquer motivo, sem aviso prévio.',
        '1.6 Os usuários são responsáveis por manter a confidencialidade das informações de sua conta, incluindo seu nome de usuário e senha. Se acredita que a sua conta foi comprometida, contacte-nos imediatamente para <a href="mailto:geral@uerba.site">geral@uerba.site.</a>',
        '1.7 uerba.site não se responsabiliza por qualquer perda ou dano decorrente do não cumprimento dos requisitos de elegibilidade ou por qualquer acesso não autorizado à sua conta.',
        '1.8 Ao utilizar a Plataforma, você reconhece e concorda em cumprir todas as leis e regulamentos aplicáveis relativos à conduta e conteúdo online, bem como estes Termos.',
      ]
    },
    {
      slug: 'user-content',
      title: '2. Conteúdo do usuário',
      terms: [
        '2.1 uerba.site incentiva os usuários a contribuir com conteúdo que aumente a diversidade da plataforma e envolva a comunidade. Isso inclui, entre outros, textos, imagens, comentários e informações sobre indivíduos e empresas.',
        '2.2 Ao enviar conteúdo para uerba.site, você garante que possui os direitos, licenças e permissões necessárias para compartilhar essas informações. Você também concede à uerba.site uma licença mundial não exclusiva, isenta de royalties, transferível, sublicenciável para usar, armazenar, exibir, reproduzir e distribuir seu conteúdo na plataforma.',
        '2.3 Os usuários reconhecem que seu conteúdo pode ser visível para outros usuários e são os únicos responsáveis pela exatidão, legalidade e adequação do conteúdo que carregam. uerba.site não endossa nem verifica o conteúdo fornecido pelos usuários.',
        '2.4 uerba.site reserva-se o direito, mas não a obrigação, de monitorar, revisar e remover conteúdo do usuário que viole estes termos, seja impróprio ou por qualquer outro motivo a nosso critério. Também poderemos encerrar as contas de usuários que infringirem repetidamente os direitos de terceiros ou violarem estes termos.',
        '2.5 Os usuários entendem e concordam que uerba.site não é responsável por qualquer perda ou dano ao seu conteúdo. É responsabilidade do usuário manter cópias de seu conteúdo e garantir sua veracidade.',
        '2.6 Ao contribuir com conteúdo, os usuários reconhecem que suas informações podem ser utilizadas de acordo com nossa <a href="/privacy-policy">Política de Privacidade.</a>',
        '2.7 uerba.site reserva-se o direito de utilizar dados agregados e anonimizados do usuário para fins analíticos e promocionais.',
        '2.8 Os utilizadores podem reportar preocupações sobre o conteúdo para <a href="mailto:geral@uerba.site">geral@uerba.site</a>, e analisaremos tais relatórios prontamente.',
        '2.9 uerba.site respeita os direitos de propriedade intelectual de terceiros e os usuários são solicitados a fazer o mesmo. Se acredita que os seus direitos de propriedade intelectual foram violados, por favor contacte-nos para <a href="mailto:geral@uerba.site">geral@uerba.site</a> com informações detalhadas, e abordaremos o assunto de acordo com a nossa cláusula de Direitos de Propriedade Intelectual.',
      ],
    },
    {
      slug: 'products-and-services',
      title: '3. Produtos e Serviços',
      terms: [
        '3.1 uerba.site é um mercado on-line abrangente que oferece uma ampla gama de produtos e serviços de terceiros. Estes incluem bens tangíveis, produtos digitais e vários serviços online, proporcionando coletivamente aos utilizadores uma experiência de compra ampla e enriquecedora.',
        '3.2 Bens Tangíveis: uerba.site serve como uma plataforma para os vendedores mostrarem e venderem produtos físicos. Isso abrange um amplo espectro, desde itens artesanais até produtos manufaturados comercialmente, proporcionando aos usuários uma ampla seleção para atender às suas diversas necessidades.',
        '3.3 Produtos Digitais: A plataforma apresenta uma extensa coleção de ofertas digitais, incluindo arquivos para download, software, e-books e conteúdo multimídia. Esses produtos digitais concedem aos usuários acesso instantâneo, promovendo um processo de transação contínuo e eficiente.',
        '3.4 Serviços Online: uerba.site facilita a prestação e aquisição de diversos serviços online. Isso abrange serviços profissionais, consultas digitais e diversas outras ofertas que atendem à crescente demanda por assistência virtual e remota.',
        '3.5 Pagamentos Automáticos: Os usuários têm a opção de se inscrever para pagamentos automáticos, principalmente para serviços e assinaturas recorrentes. Esse recurso agiliza as transações, garantindo uma experiência conveniente e ininterrupta para aqueles que mantêm relacionamentos contínuos com prestadores de serviços.',
        '3.6 Flexibilidade do fornecedor: uerba.site está empenhada em fornecer aos fornecedores flexibilidade na apresentação e gerenciamento de seus produtos e serviços. Os fornecedores reservam-se o direito de atualizar informações, preços e disponibilidade de produtos para garantir listagens precisas e atualizadas.',
        '3.7 Evolução da Plataforma: Reconhecendo a natureza dinâmica do mercado digital, uerba.site reserva-se o direito de introduzir novas categorias de produtos, recursos e serviços, garantindo que os usuários tenham acesso às ofertas mais recentes e inovadoras.',
        '3.8 Recusa ou cancelamento de pedidos: uerba.site reserva-se o direito de recusar ou cancelar pedidos a nosso critério. Isso pode ocorrer por vários motivos, incluindo, entre outros, indisponibilidade do produto, erros nos preços ou nas informações do produto ou violação destes Termos e Condições.',
        '3.9 Responsabilidade do usuário: Os usuários são responsáveis por revisar os detalhes dos produtos e serviços, incluindo os termos e condições definidos pelos fornecedores, antes de fazer uma compra. uerba.site não se responsabiliza por quaisquer disputas ou problemas decorrentes de transações entre usuários e fornecedores.',
        '3.10 Feedback e avaliações: uerba.site incentiva os usuários a fornecer comentários e avaliações construtivas sobre produtos e serviços. No entanto, espera-se que os usuários sigam as diretrizes da comunidade e evitem postar conteúdo impróprio ou malicioso. uerba.site reserva-se o direito de moderar e remover comentários que violem estas diretrizes.',
        '3.11 Direitos de Propriedade Intelectual: Os usuários devem respeitar os direitos de propriedade intelectual de fornecedores e outros usuários ao interagir com produtos e serviços no uerba.site. Para mais detalhes, consulte a cláusula de Direitos de Propriedade Intelectual nestes Termos e Condições.',
        '3.12 Garantia de qualidade: Embora uerba.site se esforce para manter altos padrões para os produtos e serviços apresentados na plataforma, não garantimos diretamente a qualidade, precisão ou adequação a uma finalidade específica de ofertas de terceiros. Os usuários são incentivados a se comunicar diretamente com os fornecedores para dúvidas e preocupações específicas.',
        '3.13 Notificação de Mudanças: uerba.site poderá modificar ou atualizar a gama de produtos e serviços oferecidos na plataforma. Os usuários serão notificados de tais alterações por meio de atualizações no site, e os usuários cadastrados também receberão notificações por e-mail. É responsabilidade do usuário manter-se informado sobre essas alterações.',
        'Ao interagir com os produtos e serviços da uerba.site, os usuários reconhecem e concordam em cumprir estes termos, promovendo um ambiente positivo e seguro para todos os participantes do nosso mercado online.',
      ],
    },
    {
      slug: 'availability',
      title: '4. Disponibilidade',
      terms: [
        '4.1 uerba.site tem o compromisso de fornecer uma plataforma acessível e disponível aos usuários, permitindo uma experiência contínua e ininterrupta. No entanto, é importante observar que a disponibilidade do site pode estar sujeita a interrupções ocasionais, manutenção ou problemas técnicos imprevistos.',
        '4.2 Melhoria Contínua: uerba.site se dedica à melhoria contínua e pode, de tempos em tempos, realizar atualizações, manutenções ou melhorias para garantir o desempenho ideal e a segurança da plataforma. Durante esses períodos, pode haver indisponibilidade temporária ou acesso limitado a determinados recursos.',
        '4.3 Questões técnicas: Embora uerba.site empregue tecnologia robusta e medidas de segurança, a natureza das plataformas online significa que podem surgir problemas técnicos, incluindo, entre outros, interrupções de servidor, problemas de conectividade ou outras circunstâncias imprevistas. uerba.site fará esforços razoáveis para abordar e resolver tais problemas prontamente.',
        '4.4 Sem compensação por tempo de inatividade: Os usuários reconhecem que uerba.site não garante acesso ininterrupto ao site e suas funcionalidades. Além disso, uerba.site não oferece compensação por quaisquer inconvenientes, perdas ou danos incorridos devido à indisponibilidade temporária, interrupções ou tempo de inatividade da plataforma.',
        '4.5 Responsabilidades do Usuário: O usuário é responsável por garantir seu próprio acesso à internet e aos equipamentos necessários para utilizar o uerba.site. Além disso, os usuários devem tomar medidas apropriadas para proteger suas contas, incluindo a manutenção de senhas seguras e a denúncia imediata de qualquer acesso não autorizado.',
        '4.6 Notificação de Manutenção Programada: Nos casos em que se prevê que a manutenção programada afetará a disponibilidade do site, uerba.site fará esforços razoáveis para fornecer aviso prévio aos usuários. Estas informações serão comunicadas através do site ou via e-mail aos usuários cadastrados.',
        '4.7 Força Maior: uerba.site não será responsabilizado por qualquer falha ou atraso no desempenho devido a circunstâncias além de seu controle razoável, incluindo, mas não se limitando a, casos fortuitos, desastres naturais, ações governamentais, quedas de energia ou outra força eventos maiores.',
        '4.8 Comunicação do Usuário: Em caso de interrupção do serviço ou alguma atualização crítica, o uerba.site poderá se comunicar com os usuários por meio de notificações no site e por e-mail. É da responsabilidade do utilizador analisar tais comunicações e manter-se informado sobre o estado da plataforma.',
        '4.9 Monitoramento Contínuo: uerba.site emprega monitoramento contínuo e se esforça para minimizar o tempo de inatividade da melhor maneira possível. A plataforma está empenhada em resolver quaisquer problemas prontamente e garantir uma experiência de usuário confiável e agradável.',
        'Ao utilizar o uerba.site, os usuários concordam com estes termos relativos à disponibilidade da plataforma, entendendo que podem ocorrer interrupções ocasionais e que o uerba.site fará esforços razoáveis para abordar e comunicar tais casos em tempo hábil.',
      ],
    },
    {
      slug: 'payment',
      title: '5. Pagamento',
      terms: [
        '5.1 uerba.site facilita pagamentos de produtos e serviços por meio de processadores de pagamento terceirizados, incluindo, entre outros, PayPal e Stripe. Ao utilizar o uerba.site, os usuários concordam em cumprir os termos e condições desses processadores de pagamento, além dos termos aqui descritos.',
        '5.2 Autorização de Pagamento: O usuário autoriza o uerba.site a processar pagamentos em seu nome pelos produtos e serviços adquiridos através da plataforma. Isto inclui transações únicas, bem como pagamentos recorrentes de assinaturas e associações, se aplicável.',
        '5.3 Processadores terceirizados: Os pagamentos são processados por processadores de pagamentos terceirizados respeitáveis e os usuários estão sujeitos aos seus termos e políticas. uerba.site não armazena nem tem acesso aos dados completos de pagamento dos usuários. Os usuários são incentivados a revisar os termos e políticas de privacidade do processador de pagamentos escolhido para uma compreensão completa de suas práticas.',
        '5.4 Alterações de Preços: uerba.site reserva-se o direito de alterar os preços dos produtos e serviços a qualquer momento. Tais alterações serão refletidas na plataforma e os usuários são responsáveis por revisar as informações atualizadas de preços antes de efetuar uma compra.',
        '5.5 Alterações em Produtos e Serviços: uerba.site pode modificar ou descontinuar determinados produtos ou serviços a qualquer momento. Os usuários reconhecem que a disponibilidade, recursos e especificações dos produtos e serviços estão sujeitos a alterações sem aviso prévio.',
        '5.6 Recusa ou cancelamento de pedidos: uerba.site reserva-se o direito de recusar ou cancelar pedidos por qualquer motivo, incluindo, entre outros, indisponibilidade de produtos, erros nos preços ou informações do produto ou violação destes Termos e Condições. Em caso de cancelamento do pedido, os usuários serão reembolsados no valor aplicável.',
        '5.7 Pagamentos Automáticos: Os usuários que optam por pagamentos automáticos para serviços e assinaturas recorrentes reconhecem que esses pagamentos serão processados no cronograma acordado. É responsabilidade do usuário gerenciar suas preferências de assinatura, incluindo cancelamento ou modificação.',
        '5.8 Moeda e Impostos: Todas as transações no uerba.site são realizadas na moeda especificada na plataforma. Os usuários são responsáveis por quaisquer impostos ou taxas aplicáveis associados às suas compras. Os preços apresentados na plataforma podem não incluir impostos e encargos adicionais, que serão comunicados durante o processo de checkout.',
        '5.9 Disputas de Pagamento: Em caso de disputa de pagamento ou transação não autorizada, os utilizadores são incentivados a contactar imediatamente o uerba.site através do email <a href="mailto:geral@uerba.site">geral@uerba.site</a>. uerba.site investigará o problema e trabalhará para resolvê-lo de acordo com suas políticas.',
        '5.10 Responsabilidade do Usuário: Os usuários são responsáveis por fornecer informações de pagamento precisas e atualizadas. uerba.site não se responsabiliza por quaisquer perdas ou danos resultantes de informações de pagamento imprecisas fornecidas pelos usuários.',
        '5.11 Comunicação: uerba.site poderá comunicar-se com os usuários sobre assuntos relacionados a pagamentos através do e-mail associado à sua conta. É responsabilidade do usuário revisar tais comunicações prontamente.',
        'Ao realizar transações no uerba.site, os usuários concordam em cumprir estes termos de pagamento, os termos dos processadores de pagamento terceirizados e quaisquer políticas adicionais descritas na plataforma. uerba.site se esforça para fornecer uma experiência de pagamento segura e transparente para todos os usuários.',
      ],
    },
    {
      slug: 'email-newsletter',
      title: '6. Boletins informativos por e-mail',
      terms: [
        '6.1 uerba.site oferece aos usuários a opção de assinar boletins informativos por e-mail, entregando conteúdo selecionado, atualizações, promoções e outras informações relevantes diretamente em sua caixa de entrada de e-mail.',
        '6.2 Desativação: Os usuários têm a opção de cancelar o recebimento de boletins informativos por e-mail da uerba.site a qualquer momento. Para exercer esta opção, os utilizadores podem ajustar as suas preferências nas configurações da sua conta ou seguir as instruções de cancelamento de subscrição fornecidas nas newsletters.',
        '6.3 Confirmação de Assinatura: Quando os usuários assinam boletins informativos por email, eles podem receber um email de confirmação para verificar suas preferências de assinatura. Essa confirmação ajuda a garantir que os usuários recebam apenas comunicações pelas quais tenham optado explicitamente.',
        '6.4 Conteúdo e Frequência: Os boletins informativos por e-mail da uerba.site podem incluir uma variedade de conteúdos, como anúncios de produtos, promoções, material educacional e atualizações sobre a plataforma. A frequência dos boletins informativos pode variar e os usuários podem esperar comunicações periódicas com base em suas preferências de assinatura.',
        '6.5 Integridade da comunicação: uerba.site está comprometido em manter a integridade de suas comunicações. Os usuários podem confiar que as newsletters enviadas por uerba.site serão relevantes para seus interesses e fornecerão informações valiosas sobre a plataforma e suas ofertas.',
        '6.6 Preocupações com privacidade: uerba.site respeita a privacidade do usuário e os endereços de e-mail fornecidos para assinaturas de boletins informativos serão tratados de acordo com nossa <a href="/privacy-policy">Política de Privacidade.</a>. Os usuários podem revisar a <a href="/privacy-policy">Política de Privacidade.</a> para obter informações detalhadas sobre como suas informações pessoais são coletadas, usadas e protegidas.',
        '6.7 Cancelamento de assinatura e acesso à conta: Os usuários que optarem por cancelar a assinatura de boletins informativos por e-mail ainda terão acesso às suas contas uerba.site e poderão continuar usando a plataforma. O cancelamento da assinatura de newsletters não afeta a capacidade do usuário de interagir com a plataforma ou fazer compras.',
        '6.8 Notificação de alterações: uerba.site reserva-se o direito de atualizar o conteúdo e a frequência dos boletins informativos por e-mail. Os utilizadores serão notificados de quaisquer alterações significativas no processo de subscrição da newsletter através de comunicações por e-mail. É responsabilidade do usuário manter-se informado sobre essas alterações.',
        '6.9 Informações de contacto: Para qualquer dúvida ou questão relativa às newsletters por email, os utilizadores podem contactar a uerba.site através do email <a href="mailto:geral@uerba.site">geral@uerba.site</a>. Agradecemos comentários e estamos prontos para ajudar com qualquer dúvida relacionada às preferências de assinatura.',
        'Ao assinar as newsletters por e-mail da uerba.site, os usuários reconhecem e concordam em receber comunicações periódicas da plataforma. A possibilidade de opt out garante que os usuários tenham controle sobre as informações que recebem, promovendo uma experiência de usuário personalizada e satisfatória.',
      ],
    },
    {
      slug: 'intelectual-property',
      title: '7. Direitos de Propriedade Intelectual',
      terms: [
        '7.1 Propriedade e Licença: uerba.site e seus licenciadores retêm todos os direitos, títulos e interesses na propriedade intelectual associada à plataforma, incluindo, mas não se limitando a marcas registradas, logotipos, marcas de serviço, imagens, texto, software e outros contente. Aos usuários é concedida uma licença limitada, não exclusiva e intransferível para acessar e usar a plataforma para fins pessoais ou comerciais, de acordo com estes Termos e Condições.',
        '7.2 Conteúdo Criado pelo Usuário: Os usuários mantêm a propriedade do conteúdo que carregam no uerba.site, incluindo textos, imagens e outros materiais ("Conteúdo do Usuário"). Ao enviar o Conteúdo do Usuário, os usuários concedem à uerba.site uma licença mundial não exclusiva, isenta de royalties, transferível, sublicenciável, para usar, armazenar, exibir, reproduzir e distribuir o Conteúdo do Usuário na plataforma.',
        '7.3 Violações de propriedade intelectual: uerba.site leva a sério os direitos de propriedade intelectual. Caso o utilizador considere que os seus direitos de propriedade intelectual foram violados na plataforma, deverá notificar imediatamente o uerba.site através do contacto <a href="mailto:geral@uerba.site">geral@uerba.site</a>. Os usuários são incentivados a fornecer informações detalhadas sobre a suposta infração, incluindo documentação relevante.',
        '7.4 Conteúdo de Terceiros: uerba.site respeita os direitos de propriedade intelectual de terceiros. Os usuários estão proibidos de fazer upload, compartilhar ou participar de qualquer atividade que viole os direitos de propriedade intelectual de terceiros. uerba.site poderá, a seu critério, remover ou desabilitar o acesso a conteúdo que supostamente infringe direitos de propriedade intelectual.',
        '7.5 Política de Direitos Autorais: uerba.site cumpre as leis de direitos autorais aplicáveis e possui uma política de encerramento de contas de usuários que sejam infratores reincidentes. Se os usuários acreditarem que seu trabalho protegido por direitos autorais foi violado, eles poderão seguir os procedimentos descritos na Política de Direitos Autorais da uerba.site.',
        '7.6 Contranotificação: Os usuários que acreditarem que seu conteúdo foi removido por engano ou como resultado de identificação incorreta poderão enviar uma contranotificação à uerba.site de acordo com os procedimentos descritos na Política de Direitos Autorais.',
        '7.7 Consequências da violação: Os usuários que violarem direitos de propriedade intelectual, incluindo violação de direitos autorais, poderão enfrentar consequências, incluindo a remoção de conteúdo, encerramento de sua conta e ações legais conforme necessário.',
        '7.8 Recursos educacionais: uerba.site pode fornecer recursos educacionais e diretrizes para ajudar os usuários a compreender e respeitar os direitos de propriedade intelectual. Os usuários são incentivados a revisar e aderir a esses recursos para promover um ambiente colaborativo e legalmente compatível.',
        '7.9 Inovação Contínua: uerba.site reserva-se o direito de inovar e introduzir novos recursos, serviços ou modificações em recursos existentes. Os usuários concordam que tais inovações poderão ser implementadas sem aviso prévio e poderão estar sujeitas a alterações nas políticas de direitos de propriedade intelectual.',
        'Ao utilizar o uerba.site, os usuários reconhecem e concordam em respeitar os direitos de propriedade intelectual da uerba.site, de seus licenciadores e de terceiros. A plataforma se esforça para fornecer um espaço onde a criatividade seja protegida e os usuários possam compartilhar seu conteúdo com confiança, dentro dos limites legais e éticos.',
      ],
    },
    {
      slug: 'prohibited-uses',
      title: '8. Usos Proibidos',
      terms: [
        'Os usuários concordam em não se envolver em quaisquer usos proibidos da Plataforma, incluindo, entre outros, atividades ilegais, violação de direitos de propriedade intelectual e uso indevido de informações pessoais.',
      ],
    },
    {
      slug: 'contact-information',
      title: '9. Informações de contato',
      terms: [
        'Para questões relativas aos presentes Termos, os utilizadores podem contactar-nos através do email <a href:"mailto:geral@uerba.site">geral@uerba.site</a>.',
      ],
    },
    {
      slug: 'changes-to-terms',
      title: '10. Alterações nos Termos',
      terms: [
        '10.1 uerba.site poderá, a seu critério, atualizar ou modificar estes Termos e Condições de tempos em tempos. Os usuários são incentivados a revisar regularmente os termos para se manterem informados sobre quaisquer alterações.',
        '10.2 Notificação de Alterações: Quando forem feitas alterações nestes Termos, uerba.site atualizará a “Data de Vigência” no início do documento. Além disso, os usuários registrados serão notificados sobre alterações significativas por e-mail para o endereço associado à sua conta.',
        '10.3 Compreensão das Mudanças: Os usuários são responsáveis por se familiarizarem com os Termos e Condições atualizados. O uso continuado da uerba.site após a data efetiva de quaisquer modificações constitui aceitação dos termos revisados. Caso os usuários não concordem com as alterações, deverão descontinuar o uso da plataforma.',
        '10.4 Comunicação por e-mail: Além de atualizar a Data de Vigência na plataforma, o uerba.site enviará notificações por e-mail aos usuários cadastrados informando-os sobre as alterações realizadas nestes Termos. Os usuários são incentivados a verificar seus e-mails regularmente e garantir que o endereço de e-mail associado à sua conta esteja correto.',
        '10.5 Optar por não participar: Caso os utilizadores não pretendam receber notificações por e-mail sobre alterações aos Termos e Condições, podem ajustar as suas preferências de e-mail nas configurações da sua conta ou contactar uerba.site em <a href:"mailto:geral@uerba.site">geral@uerba.site</a> para obter assistência.',
        '10.6 Responsabilidade do Usuário: É responsabilidade dos usuários manterem-se informados sobre alterações nestes Termos e Condições. uerba.site não se responsabiliza por quaisquer consequências decorrentes da falha do usuário em revisar e compreender os termos atualizados.',
        '10.7 Feedback e perguntas: uerba.site agradece comentários, perguntas ou preocupações sobre alterações nos Termos. Os utilizadores podem contactar <a href:"mailto:geral@uerba.site">geral@uerba.site</a> para esclarecimentos ou assistência relacionados com quaisquer modificações.',
        '10.8 Consentimento com os Termos Atualizados: Ao continuar a usar o uerba.site após a Data de Vigência dos Termos e Condições atualizados, os usuários concordam com os termos revisados e concordam em cumpri-los em sua totalidade.',
        '10.9 Versionamento de Documentos: uerba.site poderá manter versões anteriores dos Termos e Condições para fins de referência. No entanto, a versão mais recente, conforme indicada pela Data de Vigência, prevalecerá em caso de quaisquer discrepâncias.',
        'Ao interagir com uerba.site, os usuários reconhecem a natureza dinâmica das plataformas online e a necessidade de atualizações periódicas dos Termos e Condições. uerba.site está comprometido com a comunicação transparente sobre tais mudanças e incentiva os usuários a participarem da melhoria contínua da plataforma por meio de feedback construtivo.',
      ],
    },
  ],
}

const PrivacyData = {
  title: 'Política de Privacidade',
  date: 'Janeiro 1, 2024',
  footer: 'Ao utilizar uerba.site, os usuários concordam com os termos descritos nesta Política de Privacidade. Recomenda-se revisar periodicamente esta política para quaisquer atualizações.',
  categories: [
    {
      slug: 'introduction',
      title: '1. Introdução',
      terms: [
        '1.1 uerba.site está comprometido em proteger a privacidade de seus usuários. Esta Política de Privacidade descreve como as informações pessoais são coletadas, usadas e protegidas em nossa plataforma. Ao utilizar uerba.site, os usuários consentem com as práticas descritas nesta política.',
      ]
    },
    {
      slug: 'user-registration',
      title: '2. Registro do usuário e informações da conta',
      terms: [
        '2.1 Processo de Cadastro: Os usuários da uerba.site têm a comodidade de se cadastrar utilizando serviços de terceiros, como contas de redes sociais. Este processo de registro simplificado simplifica a experiência de integração, permitindo que os usuários criem contas na plataforma de forma rápida e fácil.',
        '2.2 Visualização e edição da conta: Uma vez registrados, os usuários poderão visualizar e editar as informações da sua conta na plataforma. Isso inclui detalhes como nomes de usuário, imagens de perfil e outras informações relevantes que contribuem para sua presença online no uerba.site.',
        '2.3 Limitações de exclusão de conta: É importante observar que, embora os usuários possam visualizar e editar suas informações, a plataforma atualmente não oferece uma opção para os usuários excluirem suas contas. Esta limitação existe para garantir a integridade dos dados da plataforma e pode estar sujeita a atualizações futuras de acordo com o feedback do usuário e melhorias da plataforma.',
        '2.4 Autenticação de terceiros: Ao oferecer aos usuários a opção de se cadastrarem usando serviços de terceiros, o uerba.site visa aprimorar a experiência do usuário, fornecendo uma maneira mais integrada e eficiente para os indivíduos interagirem com a plataforma sem a necessidade de um manual extenso. entrada de dados durante o processo de registro.',
        '2.5 Considerações sobre privacidade: A plataforma reconhece a importância da privacidade do usuário e se esforça para lidar com as informações da conta de forma segura e de acordo com os princípios descritos na Política de Privacidade. O uso de autenticação de terceiros foi projetado para facilitar a conveniência do usuário e, ao mesmo tempo, priorizar a proteção de dados e os padrões de privacidade.',
        '2.6 Melhoria Contínua: uerba.site está comprometida em melhorar continuamente os processos de registro de usuários e gerenciamento de contas. O feedback do usuário e a evolução dos padrões do setor serão levados em consideração para melhorar a experiência geral do usuário e garantir que as práticas de registro e informações de conta estejam alinhadas com as expectativas e preferências do usuário.',
      ]
    },
    {
      slug: 'social-sharing-content',
      title: '3. Conteúdo gerado pelo usuário e compartilhamento social',
      terms: [
        '3.1 Os usuários poderão publicar seu próprio conteúdo no uerba.site, e esse conteúdo poderá ser compartilhado nas redes sociais, aumentando a visibilidade de suas contribuições.',
        '3.2 As interações entre usuários na plataforma são atualmente limitadas; os usuários não podem interagir diretamente uns com os outros.',
      ]
    },
    {
      slug: 'target-audience',
      title: '4. Público-alvo e escopo geográfico',
      terms: [
        '4.1 uerba.site pode incluir residentes da União Europeia como parte de seu público-alvo e base de usuários.',
        '4.2 A plataforma vende produtos e serviços de empresas terceirizadas, proporcionando aos usuários uma gama diversificada de ofertas.',
      ]
    },
    {
      slug: 'payment-information',
      title: '5. Informações de pagamento e medidas de segurança',
      terms: [
        '5.1 Processamento seguro de pagamentos: uerba.site prioriza a segurança das informações de pagamento para garantir um ambiente de transação seguro e confiável para os usuários. A plataforma facilita pagamentos de produtos e serviços por meio de processadores de pagamento terceirizados de boa reputação, incluindo, entre outros, PayPal e Stripe.',
        '5.2 Autorização de Pagamento: O usuário autoriza o uerba.site a processar pagamentos em seu nome pelas compras realizadas na plataforma. Isto inclui transações únicas, bem como pagamentos recorrentes de assinaturas e associações, se aplicável.',
        '5.3 Processadores de pagamentos terceirizados: As informações de pagamento são processadas por processadores de pagamentos terceirizados estabelecidos com medidas de segurança robustas. uerba.site não armazena nem tem acesso direto aos dados completos de pagamento dos usuários. Os usuários são incentivados a revisar os termos e políticas de privacidade do processador de pagamentos escolhido para uma compreensão abrangente de suas práticas.',
        '5.4 Alterações de preços e processamento de pedidos: Os usuários reconhecem que uerba.site reserva-se o direito de alterar preços de produtos e serviços a qualquer momento. Em caso de alteração de preço, os usuários serão notificados e as informações atualizadas de preços serão refletidas na plataforma. A plataforma também se reserva o direito de recusar ou cancelar pedidos a seu critério.',
        '5.5 Armazenamento de Informações de Pagamento: uerba.site poderá armazenar informações de pagamento com a finalidade de facilitar compras futuras ou cobranças recorrentes. Esta prática é implementada para aumentar a conveniência do usuário, permitindo um processo de transação mais tranquilo para usuários recorrentes.',
        '5.6 Criptografia de dados: Para fortalecer ainda mais a segurança das informações de pagamento, uerba.site emprega protocolos de criptografia padrão da indústria. Esta criptografia ajuda a proteger dados confidenciais durante a transmissão, reduzindo o risco de acesso não autorizado.',
        '5.7 Avaliação Contínua de Segurança: A plataforma está comprometida com a avaliação contínua e o aprimoramento das medidas de segurança. São realizadas revisões regulares dos sistemas e práticas de processamento de pagamentos para alinhar com as melhores práticas do setor e abordar ameaças emergentes à segurança.',
        '5.8 Responsabilidade do usuário: Embora uerba.site implemente medidas de segurança rigorosas, os usuários são lembrados de sua responsabilidade em garantir a segurança de suas contas. Isso inclui proteger as credenciais de login, usar redes seguras e relatar imediatamente qualquer acesso não autorizado à uerba.site.',
        '5.9 Ambiente de transações seguro: Os usuários podem ter confiança no ambiente de transações seguro fornecido pelo uerba.site. A plataforma se esforça para manter os mais altos padrões de segurança no processamento de pagamentos para promover uma experiência confiável e confiável para os usuários envolvidos em transações financeiras na plataforma.',
      ]
    },
    {
      slug: 'cookies-analytics',
      title: '6. Cookies e análises',
      terms: [
        '6.1 uerba.site utiliza cookies para melhorar a experiência do usuário. Para mais informações, visite a nossa Política de Cookies.',
        '6.2 Os usuários podem optar por não permitir cookies sem interferir na sua experiência geral na plataforma.',
        '6.3 Ferramentas analíticas de terceiros podem ser usadas para analisar o comportamento do usuário. As informações pessoais são anonimizadas para evitar vinculá-las a usuários individuais.',
        '6.4 uerba.site não segue nem responde a sinais de “não rastrear”.',
      ]
    },
    {
      slug: 'communication-preferences',
      title: '7. Preferências de comunicação',
      terms: [
        '7.1 Os usuários podem optar por receber boletins informativos por e-mail e notificações push da uerba.site.',
        '7.2 Os boletins informativos por e-mail são enviados por meio de um provedor terceirizado e os usuários podem cancelar a assinatura a qualquer momento clicando no link fornecido.',
        '7.3 As notificações push são enviadas através dos próprios servidores da uerba.site.',
      ]
    },
    {
      slug: 'data-collection',
      title: '8. Coleta e uso de dados',
      terms: [
        '8.1 Tipos de Dados Coletados:',
          '8.1.1 Detalhes da conta: Inclui nomes de usuário, IDs de usuário exclusivos, senhas e outras informações necessárias para criar e gerenciar contas de usuário.',
          '8.1.2 Informações de contato: uerba.site coleta detalhes de contato, como endereços de e-mail e números de telefone, para facilitar a comunicação e fornecer atualizações essenciais.',
          '8.1.3 Informações Pessoais Básicas: Os usuários poderão fornecer voluntariamente informações pessoais básicas, como nome e país de residência, aprimorando seu perfil na plataforma.',
          '8.1.4 Informações de Pagamento: Para transações, uerba.site coleta dados de pagamento, incluindo informações de cartão de crédito ou dados bancários, garantindo um processo de pagamento seguro e eficiente.',
          '8.1.5 Conteúdo Gerado pelo Usuário: Quaisquer materiais enviados voluntariamente pelos usuários, como artigos, imagens, feedback, etc., contribuem para o conteúdo diversificado da uerba.site.',
        '8.2 Geolocalização e Dados Derivados:',
          '8.2.1 Geolocalização: uerba.site poderá solicitar acesso à geolocalização do usuário para melhor experiência do usuário e entrega de conteúdo personalizado.',
          '8.2.2 Dados Derivados: A plataforma poderá coletar dados derivados, que incluem informações derivadas de interações e preferências do usuário, contribuindo para uma experiência de usuário mais personalizada e relevante.',
        '8.3 Informações Pessoais de Terceiros:',
          '8.3.1 uerba.site poderá coletar informações pessoais de terceiros, aumentando a riqueza e a precisão dos perfis e conteúdos dos usuários.',
        '8.4 Finalidade da Coleta de Dados:',
          '8.4.1 uerba.site utiliza os dados coletados para diversos fins, incluindo, mas não se limitando a:',
            '- Criação e gerenciamento de contas de usuários.',
            '- Atendimento e gerenciamento de pedidos de produtos e serviços.',
            '- Entregar e melhorar produtos ou serviços.',
            '- Envio de informações administrativas.',
            '- Envio de comunicações de marketing e promocionais.',
            '- Envio de atualizações de produtos e serviços.',
            '- Responder às dúvidas dos usuários e oferecer suporte.',
            '- Solicitar feedback do usuário para melhorar a experiência do usuário.',
            '- Publicar depoimentos de clientes com o consentimento do usuário.',
            '- Aplicar termos e condições e políticas.',
            '- Responder a solicitações legais e prevenir danos.',
        '8.5 Perfis de Consumidores:',
          '8.5.1 uerba.site pode combinar diferentes informações pessoais para criar perfis de consumidor. Estes perfis contribuem para uma experiência mais personalizada, garantindo que os utilizadores recebem conteúdos e comunicações adaptadas às suas preferências.',
        '8.6 Práticas de Compartilhamento de Dados:',
          '8.6.1 Divulgação a Afiliadas Comerciais: As informações pessoais não serão divulgadas a afiliadas comerciais, a menos que seja necessário para o cumprimento de pedidos ou serviços, conforme acordado pelos usuários.',
          '8.6.2 Não divulgação a terceiros: uerba.site não divulga informações pessoais a terceiros, a menos que seja necessário para conformidade legal ou regulatória, conforme descrito na Política de Privacidade.',
        '8.7 Duração do armazenamento de dados:',
          '8.7.1 Os dados são armazenados pelo tempo necessário para cumprir os regulamentos e enquanto a conta do usuário permanecer ativa. uerba.site está comprometido com práticas responsáveis de gestão de dados.',
        '8.8 Transferência de dados fora da UE ou do Reino Unido:',
          '8.8.1 As informações pessoais não serão transferidas para fora da União Europeia ou do Reino Unido.',
        '8.9 Consentimento do Consumidor:',
          '8.9.1 Os usuários consentem com a coleta e uso de seus dados conforme descrito nesta Política de Privacidade ao interagir com uerba.site.',
        '8.10 Medidas de Proteção de Dados:',
          '8.10.1 uerba.site implementou medidas necessárias para manter as informações pessoais seguras, incluindo criptografia, processamento seguro de pagamentos e avaliações regulares de segurança.',
        '8.11 Notificação de violação de dados:',
          '8.11.1 Em caso de violação de dados, os usuários serão prontamente notificados por e-mail, garantindo transparência e permitindo que os usuários tomem as ações necessárias para proteger suas informações.',
        'Ao interagir com uerba.site, os usuários reconhecem e concordam com as práticas de coleta e uso de dados descritas nesta seção. Recomenda-se que os usuários revisem esta Política de Privacidade regularmente para quaisquer atualizações.',
      ]
    },
    {
      slug: 'data-handling',
      title: '9. Tratamento responsável de informações pessoais',
      terms: [
        '9.1 uerba.site usa os dados coletados para criar e gerenciar contas de usuários, atender e gerenciar pedidos, entregar e melhorar produtos ou serviços, enviar informações administrativas, enviar comunicações promocionais e de marketing, responder a perguntas, solicitar feedback do usuário, melhorar a experiência do usuário, postar clientes depoimentos, aplicar termos e condições e responder a solicitações legais.',
        '9.2 Diferentes informações pessoais podem ser combinadas para criar perfis de consumidores.',
        '9.3 As informações pessoais não serão divulgadas a afiliadas comerciais ou terceiros, exceto no caso de uma transação comercial em que o comprador possa receber tais informações.',
      ]
    },
    {
      slug: 'data-storage',
      title: '10. Armazenamento de dados e medidas de segurança',
      terms: [
        '10.1 Os dados são armazenados pelo tempo necessário para cumprir os regulamentos e enquanto a conta do usuário permanecer ativa.',
        '10.2 As informações pessoais não serão transferidas para fora da UE ou do Reino Unido.',
        '10.3 A pessoa ou empresa responsável pela proteção das informações pessoais é a mesma responsável pelo site.',
        '10.4 uerba.site implementou medidas necessárias para manter as informações pessoais seguras.',
        '10.5 Em caso de violação de dados, os usuários serão notificados por e-mail.',
      ]
    },
    {
      slug: 'contact-information',
      title: '11. Informações de contato',
      terms: [
        '11.1 Para dúvidas relativas a esta Política de Privacidade, os utilizadores podem enviar um email para <a href:"mailto:geral@uerba.site">geral@uerba.site</a>.',
      ]
    },
    {
      slug: 'changes',
      title: '12. Alterações na Política de Privacidade',
      terms: [
        '12.1 As alterações nesta Política de Privacidade serão comunicadas através da atualização da data no topo da página da Política de Privacidade, e os usuários cadastrados serão notificados via e-mail.',
      ]
    },
  ],
}




const IconBoxData = [
  {
    id: 1,
    classOption: 'bg-primary',
    icon: 'fal fa-layer-group fa-2x text-white',
    title: 'Good Performance',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state .',
  },
  {
    id: 2,
    classOption: 'bg-danger',
    icon: 'fal fa-shield-check fa-2x text-white',
    title: 'Highly Secure',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
  {
    id: 3,
    classOption: 'bg-dark',
    icon: 'fal fa-code fa-2x text-white',
    title: 'Fast Development',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
];
const FaqOneData = [
  {
    id: 1,
    faqTitle: 'How does back pricing work?',
    faqDesc:
      'Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain economically methods of empowerment for synergistic sound scenarios.',
  },
  {
    id: 2,
    faqTitle: 'How do I calculate how much price?',
    faqDesc:
      'Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently maintain economically sound scenarios and whereas client-based progressively.',
  },
  {
    id: 3,
    faqTitle: 'Can you show me an example?',
    faqDesc:
      'Dynamically visualize whereas competitive relationships. Progressively benchmark customized partnerships generate interdependent benefits rather sound scenarios and robust alignments.',
  },
];

const TestimonialData = [
  {
    id: 1,
    authorImg: '/assets/img/testimonial/1.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
  {
    id: 2,
    authorImg: '/assets/img/testimonial/2.jpg',
    authorName: 'Joan Dho',
    authorTitle: 'Founder and CTO',
    quoteTitle: 'Best Template for SAAS Company!',
    authorQuote:
      'Dynamically create innovative core competencies with effective best practices promote innovative infrastructures.',
  },
  {
    id: 3,
    authorImg: '/assets/img/testimonial/3.jpg',
    authorName: 'Ranu Mondal',
    authorTitle: 'Lead Developer',
    quoteTitle: 'It is undeniably good!',
    authorQuote:
      'Rapidiously supply client-centric e-markets and maintainable processes progressively engineer',
  },
  {
    id: 4,
    authorImg: '/assets/img/testimonial/4.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
];

const TestimonialOneData = [
  {
    id: '#testimonial-tab-1',
    activeClass: 'active',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-1.jpg',
    authorAvatar: '/assets/img/testimonial/1.jpg',
  },
  {
    id: '#testimonial-tab-2',
    title: 'Embarrassed by the First Version.',
    desc: 'Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.',
    authorName: 'Rupan Oberoi',
    authorDesn: 'Web Designer',
    authorThumb: '/assets/img/testimonial/t-2.jpg',
    authorAvatar: '/assets/img/testimonial/2.jpg',
  },
  {
    id: '#testimonial-tab-3',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-3.jpg',
    authorAvatar: '/assets/img/testimonial/3.jpg',
  },
  {
    id: '#testimonial-tab-4',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-4.jpg',
    authorAvatar: '/assets/img/testimonial/4.jpg',
  },
  {
    id: '#testimonial-tab-5',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-5.jpg',
    authorAvatar: '/assets/img/testimonial/5.jpg',
  },
];

export { 
  logoData,
  NavData,
  HeroData,
  FeatureData,
  SocialData, 

  SolutionsData,
  ServicesData,
  AppsData,
  ExtrasData,
  IntegrationsData,
  TermsData,
  PrivacyData,

  IconBoxData, 
  FaqOneData, 
  TestimonialData, 
  TestimonialOneData 
};
