import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from '@formspree/react';
import showAlert from "../alert";


const ContactFormTwo = () => {
  const [state, handleSubmit] = useForm("mqkrlkqk");

  const initialFormData = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
    botcheck: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleRecaptchaChange = (value) => {
    setFormData({ ...formData, botcheck: value });
  };

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    showAlert('form-wrap', 'Verificando...', 'alert-info')
    handleSubmit(e);

    if (state.succeeded) {
      setFormData(initialFormData);
      showAlert('form-wrap', 'Obrigado por entrar em contato! Retornaremos ao seu email assim que possivel.', 'alert-success')
    } else {
      showAlert('form-wrap', 'Ups.. Algo deu errado. Por favor tente novamente!', 'alert-danger')
    }
  };
  

  return (
    <>
      <section
        className='contact-us-form pt-60 pb-120'
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-6 col-md-8'>
              <div className='section-heading'>
                <h2>A nossa equipa de vendas e marketing está ao seu dispôr!</h2>
                <p>
                  Envie-nos os seus detalhes para que possamos resolver as suas questôes. A nossa equipa promete responder até 3 dias úteis.
                </p>
              </div>
              <form onSubmit={handleFormSubmit} className='register-form'>
                <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='firstName' className='mb-1'>
                          Nome <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                          <input
                              type='text'
                              className='form-control'
                              id='firstName'
                              required
                              placeholder='Nome'
                              name='firstName'
                              value={formData.firstName}
                              onChange={handleChange}
                              aria-label='Nome'
                          />
                      </div>
                    </div>
                    
                    <div className='col-sm-6'>
                      <label htmlFor='lastName' className='mb-1'>
                          Sobrenome <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                          <input
                              type='text'
                              className='form-control'
                              id='lastName'
                              required
                              placeholder='Sobrenome'
                              name='lastName'
                              value={formData.lastName}
                              onChange={handleChange}
                              aria-label='Sobrenome'
                          />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <label htmlFor='phone' className='mb-1'>
                          Telefone
                      </label>
                      <div className='input-group mb-3'>
                          <input
                              type='phone'
                              className='form-control'
                              id='phone'
                              placeholder='+351'
                              name='phone'
                              value={formData.phone}
                              onChange={handleChange}
                              aria-label='Telefone'
                          />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <label htmlFor='email' className='mb-1'>
                          Email <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                          <input
                              type='email'
                              className='form-control'
                              id='email'
                              required
                              placeholder='email@dominio.pt'
                              name='email'
                              value={formData.email}
                              onChange={handleChange}
                              aria-label='Email'
                          />
                      </div>
                    </div>

                    <div className='col-12'>
                      <label htmlFor='message' className='mb-1'>
                          Mensagem <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                          <textarea
                              className='form-control'
                              id='message'
                              required
                              placeholder='Em que podemos ajudar?'
                              style={{ height: '120px' }}
                              name='message'
                              value={formData.message}
                              onChange={handleChange}
                          ></textarea>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className='input-group mb-3'>
                        <ReCAPTCHA
                          className="g-recaptcha"
                          sitekey="6LfS0UMpAAAAAHHxMZceWR9Agj0dDwQR0tCJy8Im"
                          onChange={handleRecaptchaChange}
                        />                    
                      </div>
                    </div>
                    <div className="col-4">
                      <button type='submit' className='btn btn-primary'>
                          Enviar
                      </button>
                    </div>
                    <div id='form-wrap' className="col-8"></div>
                </div>
              </form>

            </div>
            <div className='col-lg-5 col-md-10'>
              <div className='contact-us-img'>
                <img
                  src='assets/img/contact-us-img-2.svg'
                  alt='contact us'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
