import React from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../../components/common/HeroTitle';
import { HeroData } from '../../data';

const HeroTwo = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 position-relative overflow-hidden'
        style={{
          background:
            "url('assets/img/shape/color-particles-2.svg') no-repeat center top",
        }}
      >
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-xl-8 col-lg-10 mb-5'>
              <div className='hero-content-wrap'>
                <HeroTitle/>
                {HeroData.buttons.map((button, index) => (
                  <Link key={index} to={button.link} className={button.classOption}>
                    {button.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className='col-lg-9'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='200'
              >
                <ul
                  className='
                    position-absolute
                    animate-element
                    parallax-element
                    widget-img-wrap
                    z-2
                  '
                >
                  <li className='layer' data-depth='0.04'>
                    <img
                      src='assets/img/hero/widget-1.png'
                      alt='widget-img'
                      className='img-fluid widget-img-1 position-absolute shadow-lg rounded-custom'
                    />
                  </li>
                  <li className='layer' data-depth='0.02'>
                    <img
                      src='assets/img/hero/widget-2.png'
                      alt='widget-img'
                      className='img-fluid widget-img-3 position-absolute shadow-lg rounded-custom'
                    />
                  </li>
                </ul>
                <img
                  src='assets/img/hero/dashboard.png'
                  alt='dashboard'
                  className='img-fluid position-relative rounded-custom mt-5'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='bg-dark position-absolute bottom-0 h-25 left-0 right-0 z--1 py-5'></div>
      </section>
    </>
  );
};

export default HeroTwo;
