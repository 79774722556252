import React from 'react';
import Layout from '../../layout/Layout';
import Navbar from '../../layout/Header/Navbar';

import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import FeatureTwo from '../../components/features/FeatureTwo';
import FooterSocial from '../../layout/Footer/FooterSocial';

import { ServicesData } from '../../data';

const Services = () => {
  return (
    <Layout>
      <PageMeta title="uerba.site | Serviços" />
      <Navbar navDark />
      <PageHeader />
      <FeatureTwo 
        noCapt
        data = {ServicesData}
      />
      <FooterSocial />
    </Layout>
  );
};

export default Services;
