import React from "react";
import { Link } from 'react-router-dom';
import { SocialData } from "../../data";

const SocialFooter = () => {
    return (
        <>
          <div className='col-md-4 col-lg-4'>
            <div className='footer-single-col text-center text-lg-end text-md-end'> {/* Center on small, end on larger */}
              <ul className='list-unstyled list-inline footer-social-list mb-0'>
                {SocialData.map((socialItem, index) => (
                  <li className='list-inline-item' key={index}>
                    <a href={socialItem.link} target="_blank"><i className={`fab ${socialItem.classOption}`}></i></a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
    );
};

export default SocialFooter;
