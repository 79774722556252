import React, {useState} from 'react';
import { Link } from "react-router-dom";

const IntegrationDetail = (data) => {
  const [priceType, setPriceType] = useState('monthly');

  const priceChange = () => {
    setPriceType(priceType === 'monthly' ? 'yearly' : 'monthly');
  };

  const currentItem = data.data
  return (
    <>
      <section className='integration-details ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='integration-details-wrap'>
                <h2>Mais informações</h2>
                <p>{currentItem.description}</p>
                {currentItem.features && (
                  <div className='integration-feature-info mt-5'>
                    <h3 className='h5 pb-4'>O que está incluído neste pacote:</h3>
                    <ul className='content-list list-unstyled'>
                      {currentItem.features.map((item, index) => (
                        <li className='mb-0' key={index}><small>{item}</small></li>
                      ))}
                    </ul>
                  </div>
                )}
                {currentItem.quote && (
                  <blockquote className='bg-white custom-shadow p-5 mt-5 rounded-custom border-4 border-primary border-top'>
                    <p className='text-muted'>
                      <i className='fas fa-quote-left me-2 text-primary'></i>{' '}
                        {currentItem.quote}
                      <i className='fas fa-quote-right ms-2 text-primary'></i>
                    </p>
                  </blockquote>
                )}

              </div>
            </div>
            
            {currentItem.plans ? (
              <div className='col-lg-4'>
                <div className='position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0'>
                  <div className='pricing-header mb-32'>
                    <div className='row'>
                      <h3 className='col package-name text-primary d-block'>{currentItem.plans.title}</h3>
                      <div className="col switch-container">
                        <input
                          value={priceType === 'monthly' ? 'monthly' : 'yearly'}
                          name="switch"
                          id="switch"
                          type="checkbox"
                          className="switch"
                          onChange={priceChange}
                        />
                        <label htmlFor="switch">
                          <span className="switch-x-text">Preço por</span>
                          <span className="switch-x-toggletext">
                            <span className="switch-x-unchecked">
                              <span className="switch-x-hiddenlabel">Unchecked: </span>mês
                            </span>
                            <span className="switch-x-checked">
                              <span className="switch-x-hiddenlabel">Checked: </span>ano
                            </span>
                          </span>
                        </label>
                      </div>

                    </div>
                    <h4 className='display-6 fw-semi-bold'>

                    {priceType === 'monthly' ? (
                      <>
                        {currentItem.plans.month}<span>/mês</span>
                      </>
                    ) : (
                      <>
                        {currentItem.plans.year}<span>/ano</span>
                      </>
                    )}
                    </h4>
                  </div>
                  <div className='pricing-info mb-4'>
                    <h4>Módulos:</h4>
                    <ul className='pricing-feature-list list-unstyled'>
                      {currentItem.plans.apps.map((app, index) => (
                        <li key={index}>
                          <i className='fas fa-arrow-right fa-2xs text-primary me-2'></i>{' '} 
                          {app.title}
                          {/* <Link to={`/apps/${app.slug}`}></Link> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link to='/request-demo' className='btn btn-outline-primary mt-2'>
                    Adquira agora!
                  </Link>
                </div>
              </div>
            ) : (
              <div className='col-lg-4'>
                <div className='position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0'>
                  <div className='mb-32'>
                    <h4 className='package-name d-block'>Este pacote ainda não está disponivel! 😓</h4>
                  </div>
                  <div className='pricing-info mb-4'>
                    <p>Enquanto trabalhamos em trazer as melhores ofertas para si e a sua empresa, pode sempre se registar à nossa newsletter para que seja o primeiro a saber das nossas novidades e promoções.</p>
                  </div>
                  <Link to='/request-for-demo' className='btn btn-outline-primary mt-2'>
                    Inscreve-te já!
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationDetail;
