import React from "react";
import { Link } from 'react-router-dom';

const CopyrightText = () => {
    return (
        <>
        <div className='copyright-text'>
            {/* <p className='mb-lg-0 mb-md-0'>&copy; 2024 uerba. Todos os direitos reservados. <small>Desenhado com 💙 por <a href="/">nós</a>.</small></p>
            <p className='mb-lg-0 mb-md-0'>
                &copy; 2024 uerba. Todos os direitos reservados.
                <div className="small d-sm-none"></div>
                <small className="d-sm-inline-block">Desenhado com 💙 por <a href="/">nós</a>.</small>
            </p> */}
            <p className='mb-lg-0 mb-md-0 d-sm-none text-center'>&copy; 2024 uerba. Todos os direitos reservados.<br/><small>Desenhado com 💙 por <a href="/">nós</a>.</small></p>
            <p className='mb-lg-0 mb-md-0 d-none d-sm-block'>&copy; 2024 uerba. Todos os direitos reservados.<small> Desenhado com 💙 por <a href="/">nós</a>.</small></p>
            <span className="small px-5 d-sm-none d-block mb-3 text-center"><Link to={'/terms-and-conditions'} className="text-muted">Termos e Condições</Link> / <Link to={'/privacy-policy'} className="text-muted">Política de Privacidade</Link></span>
            <span className="small px-5 d-none d-sm-block"><Link to={'/terms-and-conditions'} className="text-muted">Termos e Condições</Link> / <Link to={'/privacy-policy'} className="text-muted">Política de Privacidade</Link></span>
        </div>
        </>
    );
};

export default CopyrightText;
