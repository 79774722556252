import React from 'react';
import { ExtrasData } from '../../data';

const RelatedIntegration = (data) => {
  const currentItem = data.data

  return (
    <>
      <section className='related-integration ptb-120 bg-light'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12'>
              <div className='section-heading'>
                <h4 className='h5 text-primary'>Extras</h4>
                <h2>Personaliza ainda mais a tua plataforma com estes extras</h2>
              </div>
            </div>
            <div className='col-lg-7 col-md-12'>
              <div className='text-lg-end mb-4 mb-xl-0 mb-lg-0'>
                <a href='/apps' className='btn btn-primary'>
                  Saiba como
                </a>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              {currentItem && currentItem.map((extraSlug, index) => {
                const extraData = ExtrasData.find(result => result.slug === extraSlug);
                return (
                <div key={index} className='position-relative text-decoration-none connected-app-single bg-white border border-2 promo-border-hover bg-white mt-0 mt-xl-0 mt-lg-0 transition-base rounded-custom d-block overflow-hidden p-5'>
                  <div className='position-relative connected-app-content'>
                    <div className={`icon-box d-inline-block mb-32`}>
                      <i className={`${extraData.icon} text-primary`}></i>
                    </div>

                    <h5>{extraData.title}</h5>
                    <p className='mb-0 text-muted'>{extraData.description}</p>
                  </div>
                  {extraData.badge && (
                    <span className={`badge position-absolute integration-badge px-3 py-2 bg-${extraData.badge.colour}-soft text-${extraData.badge.colour}`}>
                      {extraData.badge.title}
                    </span>
                  )}
                </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RelatedIntegration;
