import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const TabOne = (data) => {
  const currentItem = data.data
  return (
    <>
      <section className='feature-tab-section ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center align-content-center'>
            <div className='col-md-10 col-lg-6'>

              <SectionTitle
                subtitle={`${currentItem.subtitle || ''}`}
                title={currentItem.title}
                description={currentItem.description}
                centerAlign
              />

            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className="d-flex justify-content-center">
                <ul
                  className='nav feature-tab-list-2 mb-0'
                  id='nav-tab'
                  role='tablist'
                >
                  {currentItem.tabs.map((tab, index) => (
                    <li key={index} className='nav-item'>
                      <Link
                        className={`nav-link ${index === 0 ? 'active' : ''}`}
                        to={`#tab-${index}`}
                        data-bs-toggle='tab'
                        data-bs-target={`#tab-${index}`}
                        role='tab'
                        aria-selected='false'
                      >
                        {tab.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className='tab-content' id='nav-tabContent'>
                {currentItem.tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={`tab-pane fade pt-60 ${index === 0 ? 'active show' : ''}`}
                    id={`tab-${index}`}
                    role='tabpanel'
                  >
                    <div className='row justify-content-center align-items-center justify-content-around'>
                      <div className='col-lg-5'>
                        <div className='feature-tab-info'>
                          <h3>{tab.caption}</h3>
                          <p>{tab.description}</p>

                          {/* <Link
                            to='/about-us'
                            className='read-more-link text-decoration-none mt-4 d-block'
                          >
                            Know More About Us
                            <i className='far fa-arrow-right ms-2'></i>
                          </Link> */}
                        </div>
                      </div>
                      {tab.img && ( 
                        <div className='col-lg-5'>
                          <img
                            src={tab.img}
                            alt='feature tab'
                            className='img-fluid mt-4 mt-lg-0 mt-xl-0'
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TabOne;
