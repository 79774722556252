import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../layout/Layout';
import Navbar from '../../layout/Header/Navbar';

import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import CtaTwo from '../../components/cta/CtaTwo';
import IntegrationDetail from '../../components/integration/IntegrationDetail';
import RelatedIntegration from '../../components/integration/RelatedIntegration';
import FooterSocial from '../../layout/Footer/FooterSocial';

import { SolutionsData } from '../../data';

const SolutionSingle = (props) => {
  const { slug } = useParams();
  const currentItem = SolutionsData.items.find(item => item.slug === slug);

  return (
    <Layout>
      <PageMeta title={`uerba.site | Soluções - ${currentItem.title}`} />
      <Navbar navDark />
      <PageHeader
        title={currentItem.title}
        desc={currentItem.caption}
        img={currentItem.icon}
        integration
        blogtags={'/solutions'}
      />
      <IntegrationDetail 
        data={currentItem}
      />
      {currentItem.extras && (
        <RelatedIntegration 
          data={currentItem.extras}
        />
      )}
      <CtaTwo />
      <FooterSocial />
    </Layout>
  );
};

export default SolutionSingle;
