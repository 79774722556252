import React from 'react';
import Layout from '../../layout/Layout';
import Navbar from '../../layout/Header/Navbar';

import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import CtaTwo from '../../components/cta/CtaTwo';
import FeatureTwo from '../../components/features/FeatureTwo';
import FooterSocial from '../../layout/Footer/FooterSocial';

import { SolutionsData } from '../../data';


const Integrations = () => {
  return (
    <Layout>
      <PageMeta title="uerba.site | Soluções" />
      <Navbar navDark />
      <PageHeader/>
      <FeatureTwo 
        noCapt
        data = {SolutionsData}
      />
      <CtaTwo />
      <FooterSocial />
    </Layout>
  );
};

export default Integrations;
