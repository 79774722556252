import React from 'react';
import NavbarLogo from '../layout/Header/NavbarLogo';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import ContactBox from '../components/contact/ContactBox';
import ContactFormTwo from '../components/contact/ContactFormTwo';
import FooterSocial from '../layout/Footer/FooterSocial';
import Layout from '../layout/Layout';

const Contact = () => {
  return (
    <Layout>
      <PageMeta title='uerba.site | Contactos' />
      <NavbarLogo navDark />
      <PageHeader
        title="Entre em contacto"
        desc="A maneira mais fácil de entrar em contacto com a nossa equipa"
      />
      {/* <ContactBox /> */}
      <ContactFormTwo />
      <FooterSocial />
    </Layout>
  );
};

export default Contact;
