import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";
import { logoData, NavData } from "../../data";

const Navbar = ({ navDark, button }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${navDark ? "position-absolute " : ""} w-100 `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src={logoData.dark}
                alt="logo"
                className="img-fluid logo-white"
                style={{maxHeight: '35px'}}
              />
              <img
                src={logoData.light}
                alt="logo"
                className="img-fluid logo-color"
                style={{maxHeight: '35px'}}
              />
            </Link>

            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                {NavData.items.map((item, index) => {
                  if (item.classOption === 'nav-item dropdown') {
                    return (
                      <li key={index} className={item.classOption}>
                        <Link
                          className="nav-link dropdown-toggle"
                          to="/"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {item.title}
                        </Link>
                        <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                          <div className={`dropdown-grid rounded-custom ${item.right ? 'width-full' : 'width-half'}`}>
                            <div className="dropdown-grid-item">
                              <a href={item.left.link}>
                                <h6 className="drop-heading">
                                  {item.left.title}
                                </h6>
                              </a>
                              {item.left.items.map((subItem, subIndex) => (
                                <Link key={subIndex} to={`${item.left.link}/${subItem.slug}`} className="dropdown-link">
                                  <span className="demo-list bg-primary rounded text-white fw-bold">
                                    <i className={subItem.icon}></i>
                                  </span>
                                  <div className="dropdown-info">
                                    <div className="drop-title">{subItem.title} {subItem.badge && (<span className={`badge bg-${subItem.badge.colour} text-white`}>{subItem.badge.title}</span>)}</div>
                                  </div>
                                </Link>
                              ))}
                            </div>

                            {item.right && (
                              <div className="dropdown-grid-item radius-right-side bg-light">
                                <a href={item.right.link}>
                                  <h6 className="drop-heading">
                                    {item.right.title}
                                  </h6>
                                </a>
                                {item.right.items.map((subItem, subIndex) => (
                                  <Link key={subIndex} to={`${item.right.link}/${subItem.slug}`} className="dropdown-link">
                                    <span className="demo-list bg-primary rounded text-white fw-bold">
                                      <i className={subItem.icon}></i>
                                    </span>
                                    <div className="dropdown-info">
                                      <div className="drop-title">{subItem.title} {subItem.badge && (<span className={`badge bg-${subItem.badge.colour} text-white`}>{subItem.badge.title}</span>)}</div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            )}

                          </div>
                        </div>
                      </li>
                    );
                  } else if (item.classOption === 'nav-link') {
                    return (
                      <li key={index}>
                        <Link to={item.link} className="nav-link">
                          {item.title}
                        </Link>
                      </li>
                    );
                  } else {
                    return null; // Render nothing if classOption is neither 'nav-item dropdown' nor 'nav-link'
                  }
                })}
              </ul>
            </div>

            <div className="action-btns mt-4 ps-3">
              {button && (
                <div className="d-sm-none">
                  {NavData.button && (
                    <>
                      {Array.isArray(NavData.button) ? (
                        NavData.button.map((button, index) => (
                          <a key={index} href={button.link} className={`${button.className || 'btn btn-primary'}`}>{button.title}</a>
                        ))
                      ) : (
                        <a href={NavData.button.link} className={`${NavData.button.className || 'btn btn-primary'}`}>{NavData.button.title}</a>
                      )}
                    </>
                  )}
                </div>
              )}
              <div className="d-none d-sm-block">
                {NavData.button && (
                  <>
                    {Array.isArray(NavData.button) ? (
                      NavData.button.map((button, index) => (
                        <a key={index} href={button.link} className={`${button.className || 'btn btn-primary'}`}>{button.title}</a>
                      ))
                    ) : (
                      <a href={NavData.button.link} className={`${NavData.button.className || 'btn btn-primary'}`}>{NavData.button.title}</a>
                    )}
                  </>
                )}
              </div>
            </div>


            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
