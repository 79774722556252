import React from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import FooterSocial from "../../layout/Footer/FooterSocial";
import HeroTwo from "./HeroTwo";
import ReviewOne from "../../components/review/ReviewOne";
import FeatureTwo from "../../components/features/FeatureTwo";
import FeatureImgEight from "../../components/features/FeatureImgEight";
import IntegrationOne from "../../components/integration/IntegrationOne";
import CtaTwo from "../../components/cta/CtaTwo";
import TestimonialOne from "../../components/testimonial/TestimonialOne";
import LatestBlog from "../../components/blog/LatestBlog";

import { ServicesData } from '../../data';

const HomeSassTwo = () => {
  return (
    <Layout>
      <Navbar button />
      <HeroTwo />
      {/* <ReviewOne /> */}
      <FeatureImgEight />

      <FeatureTwo 
        data = {ServicesData}
      />
      {/* <TestimonialOne hasSubtitle /> */}
      <IntegrationOne />
      {/* <LatestBlog /> */}
      <CtaTwo />
      <FooterSocial />
    </Layout>
  );
};

export default HomeSassTwo;
