import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logoData } from "../../data";

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${navDark ? "position-absolute " : ""} w-100 `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src={logoData.dark}
                alt="logo"
                className="img-fluid logo-white"
                style={{maxHeight: '35px'}}
              />
              <img
                src={logoData.light}
                alt="logo"
                className="img-fluid logo-color"
                style={{maxHeight: '35px'}}
              />
            </Link>

          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
