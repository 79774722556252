import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import CtaTwo from '../../components/cta/CtaTwo';
import IntegrationTwo from '../../components/integration/IntegrationTwo';
import FooterSocial from '../../layout/Footer/FooterSocial';
import Layout from '../../layout/Layout';

const Integrations = () => {
  return (
    <Layout>
      <PageMeta title="uerba.site | Integrações" />
      <Navbar navDark />
      <PageHeader
        title="Colaboramos com as principais empresas de software"
        desc="Busque dinamicamente a convergência, em vez de melhorias de processos 24 horas por dia, 7 dias por semana, desenvolvendo itens de ação de atendimento ao cliente de ponta a ponta."
      />
      <IntegrationTwo />
      <CtaTwo />
      <FooterSocial />
    </Layout>
  );
};

export default Integrations;
