import React from 'react';
import SocialsFooter from '../../components/social/SocialFooter.js';
import CopyrightText from '../../components/CopyrightText.js';

const FooterSocial = () => {
  return (
    <>
      <footer className='footer-section'>
        <div className='footer-bottom bg-dark text-white py-4'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-7 col-lg-7'>
                <CopyrightText />
              </div>
              <SocialsFooter />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterSocial;
