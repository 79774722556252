import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../layout/Layout';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';

import TabOne from '../../components/tabs/TabOne';
import FaqTwo from '../../components/faqs/FaqTwo';
import CyberCta from '../../components/cta/CyberCta';
import PromoThree from '../../components/promo/PromoThree';
import FooterSocial from '../../layout/Footer/FooterSocial';


import { ServicesData } from '../../data';

const SingleService = (props) => {
  const { slug } = useParams();
  const currentItem = ServicesData.items.find(item => item.slug === slug);

  return (
    <Layout>
      <PageMeta title={`uerba.site | Serviços - ${currentItem.title}`} />
      <Navbar navDark />
      <PageHeader
        title={currentItem.title}
        desc={currentItem.caption}
        
        integration
        blogtags={'/services'}
      />
      
      <TabOne 
        data = {currentItem}
      />
      <CyberCta />
      <FaqTwo data={currentItem.faqs} />
      {/* <PromoThree hasBg /> */}
      <FooterSocial />
    </Layout>
  );
};

export default SingleService;
