import React from 'react';

import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';
import Navbar from '../layout/Header/Navbar';
import SingleSupportDetails from '../components/help-center/SingleSupportDetails';
import FooterSocial from '../layout/Footer/FooterSocial';

import { PrivacyData } from '../data';

const SingleHelpCenter = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <SingleSupportDetails data={PrivacyData} />
      <FooterSocial />
    </Layout>
  );
};

export default SingleHelpCenter;
