import React from 'react';
import { Link } from 'react-router-dom';
import { IntegrationsData } from '../../data';

const IntegrationOne = () => {
  return (
    <>
      <section className='integration-section ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-3'>
              <div className='integration-list-wrap'>
                {IntegrationsData.slice(0, 6).map((item, index) => (
                  <a
                    key={index}
                    className={item.className}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title={item.title}
                  >
                    <img
                      src={item.img.icon}
                      alt='integration'
                      className='img-fluid'
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='section-heading text-center my-5 my-lg-0 my-xl-0'>
                <h4 className='text-primary h5'>Integrações</h4>
                <h2>Colaboramos com as principais e mais populares ferramentas de software disponíveis online</h2>
                <Link to='/integrations' className='mt-4 btn btn-primary'>
                  Ver todas as integrações
                </Link>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='col-lg-4'>
                <div className='integration-list-wrap'>
                  {IntegrationsData.slice(6, 12).map((item, index) => (
                    <a
                      key={index}
                      className={item.className}
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title={item.title}
                    >
                      <img
                        src={item.img.icon}
                        alt='integration'
                        className='img-fluid'
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center mt-100'>
            {IntegrationsData.filter(item => item.hero).map((item, index) => (
              <div key={index} className='col-lg-5 col-md-12'>
                <a
                  className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-custom-light rounded-circle p-2 d-inline-block'>
                      <img
                        src={item.img.icon}
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>{item.title}</h5>
                    <p className='mb-0 text-body'>{item.caption}</p>
                  </div>
                  {item.badge && (
                    <span className={`position-absolute badge integration-badge px-3 py-2 bg-${item.badge.colour}-soft text-${item.badge.colour}`}>
                      {item.badge.title}
                    </span>
                  )}
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationOne;
