import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ noCapt, data, cardDark }) => {
  return (
    <>
      <section
        className={`feature-section ${data.title ? 'ptb-120' : 'pt-50 pb-10'} ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
            <SectionTitle
              subtitle={`${noCapt ? '' : data.subtitle}`}
              title={data.title}
              description={data.description}
              centerAlign
              dark={cardDark}
            />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                {data.items.map((item, index) => (
                  <div
                    key={index}
                    className={`shadow-sm ${item.highlight ? 'highlight-card' : 'feature-card'} rounded-custom p-5 ${
                      cardDark ? 'bg-custom-light promo-border-hover border border-2 border-light text-white' : 'bg-white'
                    }`}
                    data-aos='fade-up'
                    data-aos-delay='50'
                  >
                    <div className={`icon-box d-inline-block mb-32`}>
                      <i className={`${item.icon} text-${item.colour}`}></i>
                    </div>

                    {item.badge && (
                      <span className={`badge feature-badge px-3 py-2 bg-${item.badge.colour}-soft text-${item.badge.colour}`}>
                        {item.badge.title}
                      </span>
                    )}

                    <div className='feature-content'>
                      <h3 className='h5'>{item.title}</h3>
                      <p>{item.description}</p>

                      {item.highlight && item.features && (
                        <>
                          <h6 className='mt-4'>Inclui:</h6>
                          <ul className='list-unstyled mb-0'>
                            {item.features.map((feature, index) => (
                              <li key={index} className='py-1'>
                                <i className={`fad fa-check-circle me-2 text-${item.colour}`}></i>
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>

                    <Link to={`${data.prefix}/${item.slug}`} className='link-with-icon text-decoration-none mt-3'>
                      Mais detalhes <i className='far fa-arrow-right'></i>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
