import React from "react";
import { Link } from "react-router-dom";
import { logoData, NavData } from "../../data";

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center mt-4">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              src={logoData.dark}
              alt="logo"
              className="img-fluid ps-2"
            />
          </Link>

          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>

        </div>

        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            {NavData.items.map((item, index) => {
              if (item.classOption === 'nav-item dropdown') {
                return (
                  <li key={index} className={item.classOption}>
                    <Link
                      className="nav-link dropdown-toggle"
                      to="/"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {item.title}
                    </Link>
                    <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                      <div className={`dropdown-grid rounded-custom ${item.right ? 'width-full' : 'width-half'}`}>
                        <div className="dropdown-grid-item">
                          <a href={item.left.link}>
                            <h6 className="drop-heading">
                              {item.left.title}
                            </h6>
                          </a>
                          {item.left.items.map((subItem, subIndex) => (
                            <Link key={subIndex} to={`${item.left.link}/${subItem.slug}`} className="dropdown-link">
                              <span className="demo-list bg-primary rounded text-white fw-bold">
                                <i className={subItem.icon}></i>
                              </span>
                              <div className="dropdown-info">
                                <div className="drop-title">{subItem.title} {subItem.badge && (<span className={`badge bg-${subItem.badge.colour} text-white`}>{subItem.badge.title}</span>)}</div>
                              </div>
                            </Link>
                          ))}
                        </div>

                        {item.right && (
                          <div className="dropdown-grid-item radius-right-side bg-light">
                            <a href={item.right.link}>
                              <h6 className="drop-heading">
                                {item.right.title}
                              </h6>
                            </a>
                            {item.right.items.map((subItem, subIndex) => (
                              <Link key={subIndex} to={`${item.right.link}/${subItem.slug}`} className="dropdown-link">
                                <span className="demo-list bg-primary rounded text-white fw-bold">
                                  <i className={subItem.icon}></i>
                                </span>
                                <div className="dropdown-info">
                                  <div className="drop-title">{subItem.title} {subItem.badge && (<span className={`badge bg-${subItem.badge.colour} text-white`}>{subItem.badge.title}</span>)}</div>
                                </div>
                              </Link>
                            ))}
                          </div>
                        )}

                      </div>
                    </div>
                  </li>
                );
              } else if (item.classOption === 'nav-link') {
                return (
                  <li key={index}>
                    <Link to={item.link} className="nav-link">
                      {item.title}
                    </Link>
                  </li>
                );
              } else {
                return null; // Render nothing if classOption is neither 'nav-item dropdown' nor 'nav-link'
              }
            })}
          </ul>

          {NavData.button && (
            <div className="action-btns mt-4 ps-3">
              {Array.isArray(NavData.button) ? (
                NavData.button.map((button, index) => (
                  <a key={index} href={button.link} className={`${button.className || 'btn btn-primary'}`}>{button.title}</a>
                ))
              ) : (
                <a href={NavData.button.link} className={`${NavData.button.className || 'btn btn-primary'}`}>{NavData.button.title}</a>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
