import React, { useState } from "react";
import { Link } from 'react-router-dom';

import { useForm } from '@formspree/react';
import showAlert from "../alert";

const ContactsForm = () => {
  const [state, handleSubmit] = useForm("moqglqww");

  const initialFormData = {
    name: '',
    email: '',
    companyWebsite: '',
    phone: '',
    budget: 'null',
    country: 'null',
    message: '',
    newsletterCheck: false
  };

  const [formData, setFormData] = useState(initialFormData);
  const [newsletterChecked, setNewsletterChecked] = useState(false);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({ ...formData, [id]: type === 'checkbox' ? checked : value });
  };

  const handleNewsletterChange = (e) => {
    setNewsletterChecked(e.target.checked);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    showAlert('form-wrap', 'Verificando...', 'alert-info');
    handleSubmit(e);
    // setFormData(initialFormData);

    if (state.succeeded) {
      
      showAlert('form-wrap', 'Obrigado por entrar em contato! Retornaremos ao seu email assim que possivel.', 'alert-success');
    } else {
      showAlert('form-wrap', 'Ups.. Algo deu errado. Por favor tente novamente!', 'alert-danger');
    }
  };

  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
          Preenche o formulário e entraremos em contato assim que possível
          </h3>

          <form onSubmit={handleFormSubmit} className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                <input
                  value={formData.name}
                  id='name'
                  name='Nome'
                  type='text'
                  className='form-control'
                  placeholder='Nome'
                  onChange={handleChange}
                  required
                />
                </div>
              </div>
              <div className='col-sm-6 '>
                <div className='input-group mb-3'>
                  <input
                    value={formData.email}
                    id='email'
                    name='E-Mail'
                    type='email'
                    className='form-control'
                    placeholder='Email'
                    aria-label='email'
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    value={formData.companyWebsite}
                    id='companyWebsite'
                    name='Website'
                    type='text'
                    className='form-control'
                    placeholder='Website da empresa'
                    aria-label='company-website'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    value={formData.phone}
                    id='phone'
                    name='Telemóvel'
                    type='phone'
                    className='form-control'
                    placeholder='Número de Telemóvel'
                    aria-label='phone'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <select
                    id='budget'
                    name='Orçamento'
                    className='form-control form-select'
                    data-msg='Please select your budget.'
                    onChange={handleChange}
                    required
                  >
                    <option value={formData.budget} disabled selected hidden>Orçamento</option>
                    <option value='Nenhum'>Nenhum, quero começar agora</option>
                    <option value='< 1,000>'>&lt; €1,000</option>
                    <option value='1,000 a 5,000'>€1,000 a €5,000</option>
                    <option value='5,000 a 10,000'>€5,000 a €10,000</option>
                    <option value='10,000 a 20,000'>€10,000 a €20,000</option>
                    <option value='20,000 <'>€20,000 &gt;</option>
                  </select>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <select
                    id='country'
                    name='País'
                    className='form-control form-select'
                    data-msg='Please select your country.'
                    defaultValue='Default'
                    onChange={handleChange}
                    required=''
                  >
                    <option value={formData.country} disabled selected hidden>País</option>
                    <option value='PT'>Portugal</option>
                    <option value='GB'>United Kingdom</option>
                  </select>
                </div>
              </div>
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    value={formData.message}
                    id='message'
                    name='message'
                    className='form-control'
                    placeholder='Diga-nos a que pretende usar a nossa plataforma, ou dê-nos uma breve introdução à sua empresa'
                    style={{ height: '120px' }}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    value={formData.newsletterCheck}
                    id='newsletterCheck'
                    name='Newsletter'
                    className='form-check-input'
                    type='checkbox'
                    onChange={handleNewsletterChange}
                  />
                  <label
                    className='form-check-label small'
                    htmlFor='newsletterCheck'
                  >
                    Sim, gostaria de receber e-mails de marketing ocasionais - tendo o direito de cancelar a qualquer momento.
                    <Link to='/privacy-policy'> <small>Ver Política de Privacidade</small></Link>
                  </label>
                </div>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                >
                  Enviar
                </button>
                <div id='form-wrap'></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;
