let currentAlert = null;

const showAlert = (wrap, message, className) => {
  if (currentAlert) {
    currentAlert.remove();
  }

  const alertDiv = document.createElement('div');
  alertDiv.className = `alert ${className} mt-1`;
  
  // Create a span element for the text content
  const textSpan = document.createElement('span');
  textSpan.className = `fs-s`;
  textSpan.textContent = message;

  // Append the text content span to the alert div
  alertDiv.appendChild(textSpan);

  // Get the form-wrap div by its id
  const formWrapDiv = document.getElementById(wrap);
  
  // Append the alert to the form-wrap div
  formWrapDiv.appendChild(alertDiv);

  currentAlert = alertDiv;

  // Remove the alert after a certain time
  setTimeout(() => {
    alertDiv.remove();
    currentAlert = null;
  }, 5000);
};

export default showAlert;
