import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTwo = (data) => {
  const items = data.data
  return (
    <>
      <section className='faq-section ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <SectionTitle 
                subtitle='FAQ' 
                title='Perguntas Frequentes' 
                description='Caso ainda esteja em dúvida, não hesite em nos contactar da maneira que lhe der mais jeito.' 
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                
              {items.map((faq, index) => (
                  <div key={index} className={`accordion-item border border-2 ${index === 0 ? 'active' : ''}`}>
                    <h5 className='accordion-header' id={`faq-${index}`}>
                      <button
                        className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapse-${index}`}
                        aria-expanded={`${index === 0 ? 'true' : 'false'}`}
                      >
                        {faq.title}
                      </button>
                    </h5>
                    <div
                      id={`collapse-${index}`}
                      className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                      aria-labelledby={`faq-${index}`}
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>{faq.description}</div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTwo;
