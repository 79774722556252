import React from 'react';
import { Link } from 'react-router-dom';

const SingleSupportDetails = (data) => {
  const termsData = data.data

  const scrollToCategory = (categorySlug) => {
    const categoryElement = document.getElementById(categorySlug);
    if (categoryElement) {
      const yOffset = -100; // Adjust this offset as needed
      const y = categoryElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      <section className='support-content ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-4 col-md-4 d-none d-md-block d-lg-block'>
              <div className='support-article-sidebar sticky-sidebar'>
                <div className='nav flex-column nav-pills support-article-tab bg-light rounded-custom p-5'>
                  <h5>{termsData.title}</h5>
                  {termsData.categories && termsData.categories.map((category, index) => (
                    <Link key={index} to={`#${category.slug}`} onClick={() => scrollToCategory(category.slug)} className='text-muted text-decoration-none py-2 d-block'>
                      {category.title}
                    </Link>
                  ))}
                </div>
                {/* <div className='bg-light p-5 mt-4 rounded-custom quick-support'>
                  <Link
                    to='/contact-us'
                    className='text-decoration-none text-muted d-flex align-items-center py-2'
                  >
                    <div className='quick-support-icon rounded-circle bg-success-soft me-3'>
                      <i className='far fa-ballot-check text-success'></i>
                    </div>
                    <div className='contact-option-text'>
                      Quick Support Form
                    </div>
                  </Link>
                  <Link
                    to='/mailto:info@themetags.com'
                    className='text-decoration-none text-muted d-flex align-items-center py-2'
                  >
                    <div className='quick-support-icon rounded-circle bg-primary-soft me-3'>
                      <i className='far fa-envelope text-primary'></i>
                    </div>
                    <div className='contact-option-text'>
                      info@themetags.com
                    </div>
                  </Link>
                  <Link
                    to='#'
                    target='_blank'
                    className='text-decoration-none text-muted d-flex align-items-center py-2'
                  >
                    <div className='quick-support-icon rounded-circle bg-danger-soft me-3'>
                      <i className='far fa-comment-alt-lines text-danger'></i>
                    </div>
                    <div className='contact-option-text'>Live Support Chat</div>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className='col-lg-8 col-md-8 p-lg-5'>
              <div className='support-article-wrap'>

                <h1 className='display-5 mb-4 fw-bold'>{termsData.title}</h1>
                <span>{termsData.date}</span>
                <div className='mb-5'></div>
                {termsData.introduction && termsData.introduction.map((intro, index) => (
                  <p key={index}>
                    {intro.split('<a').map((part, idx) => {
                      if (idx === 0) {
                        return <span key={idx}>{part}</span>;
                      } else {
                        const [text, rest] = part.split('</a>');
                        const hrefStart = text.indexOf('href="') + 6;
                        const hrefEnd = text.indexOf('"', hrefStart);
                        const href = text.substring(hrefStart, hrefEnd);
                        const anchorText = text.substring(text.indexOf('>') + 1);
                        return (
                          <span key={idx}>
                            <a href={href}>{anchorText}</a>
                            {rest}
                          </span>
                        );
                      }
                    })}
                  </p>
                ))}

                {termsData.categories.map((category, index) => (
                  <div key={index} className='my-5'>
                    <h3 id={category.slug} className='h5'>{category.title}</h3> 

                    {category.terms.map((term, idx) => (
                      <p key={idx}>
                        {term.includes('<a ') ? (
                          <span dangerouslySetInnerHTML={{ __html: term }} />
                        ) : (
                          term
                        )}
                      </p>
                    ))}
                    
                    {/* {category.terms.map((term, idx) => (
                      <p key={idx}>{term}</p>
                    ))} */}
                  </div>
                ))}

                <p>{termsData.footer}</p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleSupportDetails;
