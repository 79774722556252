import React from 'react';
import { Link } from 'react-router-dom';
import { IntegrationsData } from '../../data';

const IntegrationTwo = () => {
  return (
    <>
      <section className='integration-section bg-light ptb-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='integration-wrapper position-relative w-100'>
                <ul className='integration-list list-unstyled mb-0'>
                  {IntegrationsData.map((item, index) => (
                    
                    <li data-aos='fade-up' data-aos-delay='50'>
                      <div className='single-integration'>
                        <img
                          src={item.img.icon}
                          alt='integration'
                          className='img-fluid'
                        />
                        <h6 className='mb-0 mt-4'>{item.title}</h6>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationTwo;
